import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  InputAdornment, 
  makeStyles, 
  Box,
  Typography
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { api } from '../../../services';
import {
  SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR, STATUS_ATIVO,
} from '../../../utils';
import { TextInput, AutoCompleteRemoto } from '../../Inputs';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
  },
  withoutLabel: {
  },
  textField: {
  },
}));

const inputs = [
  /*{
    nome: 'gerente de caixa',
    defaultValue: null,
    label: 'Responsável pela retirada',
  },*/
  {
    nome: 'senha_responsavel',
    defaultValue: '',
    label: 'Senha Responsável',
  },
  {
    nome: 'transportador',
    defaultValue: null,
    label: 'Transportador da retirada',
  },
  {
    nome: 'senha_transportador',
    defaultValue: '',
    label: 'Senha Transportador',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const ETAPA_1 = 0
const ETAPA_2 = 1

const DialogoNota = forwardRef(({
  handleSalvar, 
  title, 
  enqueueSnackbar, 
  mensagemSucesso = 'Item removido com sucesso!', 
  hasTransportador = false
}, ref) => {
  const [open, setOpen] = useState(false);
  const [textInit, setTextInit] = useState('')
  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [codigo, setCodigo] = useState(0);
  const [dados, setDados] = useState(null);
  const [etapa, setEtapa] = useState(ETAPA_1 );
  const [transportador, setTransportador] = useState(null);
  const [passwordTransportador, setPasswordTransportador] = useState('');
  const refControle = useRef(false)

  const refs = useRef([]);

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen(codigoNew = 0, dados = null) {
      setOpen(true);
      setCodigo(codigoNew);
      setTextInit('')
      setUserId(0)
      setUsername('')
      setPassword('')
      setDados(dados)
      setEtapa(ETAPA_1)
      setTransportador(null)
      setPasswordTransportador('')
    },
  }));

  async function handleSalvarDialog() {
    if(!refControle.current) {
      refControle.current = true;
      if (!erroExistente) {
        try {
          if(!hasTransportador) {
            const dataConfig = await api.post('/login_retirada_caixa', {
              username,
              password,
            });
            if (dataConfig.data.status) {
              handleSalvar(dataConfig.data.user_id, dados, codigo, null);
              setOpen(false);
              enqueueSnackbar(mensagemSucesso, {
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Senha incorreta!', {
                variant: 'error',
              });
            }
          } else {
            const dataConfig = await api.post('/login_retirada_caixa', {
              username,
              password,
            });
            if (dataConfig.data.status) {
              const dataConfig2 = await api.post('/login_retirada_caixa', {
                username: transportador.username,
                password: passwordTransportador,
              });
              if (dataConfig2.data.status) {
                handleSalvar(dataConfig.data.user_id, dados, codigo, dataConfig2.data.user_id);
                setOpen(false);
                enqueueSnackbar(mensagemSucesso, {
                  variant: 'success',
                });
              } else {
                enqueueSnackbar('Senha incorreta do transportador!', {
                  variant: 'error',
                });
              }
            } else {
              enqueueSnackbar('Senha incorreta do gerente!', {
                variant: 'error',
              });
            }
          }
        } catch (e) {
          enqueueSnackbar('Erro inesperado!', {
            variant: 'error',
          });
        } finally {
          refControle.current = false;
        }
      }
    }
  }

  function validateTextInit() {
    let error = '';
    if (!textInit) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePassword() {
    let error = '';
    if (!password) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateUserTransportador() {
    let error = '';
    if (!transportador) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePasswordTransportador() {
    let error = '';
    if (!passwordTransportador) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
      '',
      ''
    ];
    errosOld[0] = etapa === ETAPA_1 ? validateTextInit() : validatePassword();
    errosOld[1] = etapa === ETAPA_1 ? '' : hasTransportador ? validateUserTransportador() : '';
    errosOld[2] = etapa === ETAPA_1 ? '' : hasTransportador ? validatePasswordTransportador() : '';

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  async function getDadosQrcode() {
    try {
      const response = await api.post(`/dados_qrcode_gerente_caixa`, {
        hash: textInit.replace(/;/g, '/')
        //hash: textInit
      })
      const { 
        status,
        message,
        data
      } = response.data

      if(status) {
        setTextInit('')
        setUsername(data.username)
        setUserId(data.user_id)
        setEtapa(ETAPA_2)
        setPassword('')
        setTransportador(null)
        setPasswordTransportador('')
      } else {
        setTextInit('')
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
      
    } catch(e) {
      setTextInit('')
      enqueueSnackbar('Erro inesperado!', {
        variant: 'error',
      });
    }
  }

  ///////////

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  ///////////////////

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title" style={{ color: 'white', backgroundColor: SECONDARY_COLOR, textAlign: 'center' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          {etapa === ETAPA_1 
            ? 
            <TextInput
              name='qrcode'
              label="Insira aqui o Qrcode"
              value={textInit}
              onChange={(event) => {
                setTextInit(event.target.value);
              }}
              fullWidth
              handleKey={(action, name) => {
                switch (name) {
                  case FECHAR_VENDA_ACTION:
                    getDadosQrcode()
                    break;
                  case CANCELAR_VENDA_ACTION:
                    handleCloseDialog();
                    break;
                  case ESC_ACTION:
                    handleCloseDialog();
                    break;
                  default:
                    break;
                }
              }}
              handleEnter={() => {
                getDadosQrcode()
              }}
              error={erros[0] !== ''}
              helperText={erros[0]}
              autoFocus
            />
            :
            <Box>
              <Typography variant="h6" gutterBottom>
                Gerente
              </Typography>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    name='username'
                    label="Usuário"
                    value={username}
                    onChange={() => {}}
                    fullWidth
                    handleKey={() => {}}
                    handleEnter={() => {}}
                    disabled
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput
                    ref={refs.current[0]}
                    handleEnter={() => {
                      hasTransportador ? handleNextInput(0) : handleSalvarDialog()
                    }}
                    name='password'
                    type={'password'}
                    label="Senha"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    fullWidth
                    handleKey={(action, name) => {
                      switch (name) {
                        case FECHAR_VENDA_ACTION:
                          handleSalvarDialog()
                          break;
                        case CANCELAR_VENDA_ACTION:
                          handleCloseDialog();
                          break;
                        case ESC_ACTION:
                          handleCloseDialog();
                          break;
                        default:
                          break;
                      }
                    }}
                    error={erros[0] !== ''}
                    helperText={erros[0]}
                    autoFocus
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {<Visibility />}
                        </IconButton>
                      </InputAdornment>
                      )}
                    labelWidth={70}
                  />
                </Box>
              </Box>
              {
                hasTransportador 
                ? 
                <>
                  <Typography variant="h6" gutterBottom>
                    Transportador
                  </Typography>

                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <AutoCompleteRemoto
                        ref={refs.current[1]}
                        handleEnter={() => handleNextInput(1)}
                        id="transportador"
                        name={'transportador'}
                        label="Usuário"
                        value={transportador}
                        onChange={(value) => setTransportador(value)}
                        handleKey={(action, name) => {
                          switch (name) {
                            case FECHAR_VENDA_ACTION:
                              handleSalvarDialog()
                              break;
                            case CANCELAR_VENDA_ACTION:
                              handleCloseDialog();
                              break;
                            case ESC_ACTION:
                              handleCloseDialog();
                              break;
                            default:
                              break;
                          }
                        }}
                        resource="users"
                        nested={[]}
                        toString={false}
                        fullwidth
                        campo="username"
                        filters={{ status: STATUS_ATIVO }}
                        getOptionSelected={(option, value) => option.username === value.username}
                        getOptionLabel={(option) => `${option.username}`}
                        error={erros[1] !== ''}
                        helperText={erros[1]}
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <TextInput
                        ref={refs.current[2]}
                        handleEnter={() => handleNextInput(2)}
                        name='password'
                        type={'password'}
                        label="Senha"
                        value={passwordTransportador}
                        onChange={(event) => {
                          setPasswordTransportador(event.target.value);
                        }}
                        fullWidth
                        handleKey={(action, name) => {
                          switch (name) {
                            case FECHAR_VENDA_ACTION:
                              handleSalvarDialog()
                              break;
                            case CANCELAR_VENDA_ACTION:
                              handleCloseDialog();
                              break;
                            case ESC_ACTION:
                              handleCloseDialog();
                              break;
                            default:
                              break;
                          }
                        }}
                        error={erros[2] !== ''}
                        helperText={erros[2]}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              {<Visibility />}
                            </IconButton>
                          </InputAdornment>
                          )}
                        labelWidth={70}
                      />
                    </Box>
                  </Box>
                </> 
                : 
                null
              }
            </Box>
          }
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={ etapa === ETAPA_1 ? getDadosQrcode : handleSalvarDialog}
            disabled={erroExistente}
            tabindex="-1"
          >
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
