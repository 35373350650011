import axios from 'axios';

import {
  URL_SERVIDOR_IMPRESSORA,
} from '../utils';

const api = axios.create({
  baseURL: URL_SERVIDOR_IMPRESSORA,
});

async function imprimir(id, type) {
  try {
    const url = `/imprimir-document/${id}/${type}`;
    await api.post(url, {
      url_api: process.env.REACT_APP_HOST_API,
    });
    return 1;
  } catch (e) {
    return -1;
  }
}

export async function imprimirNotaVenda(id) {
  return await imprimir(id, 1);
}

export async function imprimirNotaRetirada(id) {
  return await imprimir(id, 6);
}

export async function imprimirRelatorioCaixa(id) {
  return await imprimir(id, 3);
}

export async function imprimirNotaPreVenda(id) {
  return await imprimir(id, 4);
}

export async function imprimirNotaAbertura(id) {
  return await imprimir(id, 6);
}

export async function imprimirPacote(id) {
  return await imprimir(id, 6);
}

export async function imprimirRelatorioAberturaCaixa(id) {
  return await imprimir(id, 5);
}

export async function imprimirTroco(id) {
  return await imprimir(id, 12);
}
