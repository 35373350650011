import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { useSnackbar } from 'notistack';

import {
  AutoCompleteLocal,
} from '../../components';
import {
  getCaixaId,
  setCaixaId,
  getListAllAPI,
  login,
  setUsername,
  setUserId,
  isAuthenticated,
  setSessionId,
  api,
  imprimirNotaAbertura,
  getTipoCaixaId,
  setTipoCaixaId,
  imprimirRelatorioAberturaCaixa
} from '../../services';
import {
  ATALHO_AVANCAR,
  STATUS_ATIVO,
  APP_VERSION
} from '../../utils';
import DialogoPesquisaCodigo from './DialogoPesquisaCodigo2';
import InputPassaword from './InputPassaword';
import InputUsername from './InputUsername';
import DialogoReconectarSessao from './DialogoReconectarSessao'
import DialogoAlteraCaixa from './DialogoAlteraCaixa'
import Chip from '@material-ui/core/Chip';
import SyncIcon from '@material-ui/icons/Sync';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import DialogoAutorizacaoComQrcode from '../../components/Dialogos/DialogoAutorizacaoComQrcode'


const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
    opacity: '0.90',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  margin: {
    margin: theme.spacing(1),
  },
  settings: {
    color: 'red',
  },
}));

const inputs = [
  {
    nome: 'conta',
    defaultValue: '',
    label: 'Conta',
  },
  {
    nome: 'tipo_sessao',
    defaultValue: '',
    label: 'Tipo de Sessão',
  },
  {
    nome: '',
    defaultValue: '',
    label: 'Usuário',
  },
  {
    nome: '',
    defaultValue: '',
    label: 'Senha',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const MUDAR_CAIXA_ACTION = 'F2';
const RECONECTAR_SESSAO_ACTION = 'F10';

const LoginTela = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const refs = useRef([]);
  const [optionsCaixas, setOptionsCaixas] = useState([]);
  const [optionsTiposCaixas, setOptionsTiposCaixas] = useState([]);
  const [caixa, setCaixa] = useState(null);
  const [tipoCaixa, setTipoCaixa] = useState(null);
  const refDialogoSenha = useRef(null);
  const refDialogoPesquisarCodigo = useRef(null);
  const refDialogoReconectarSessao = useRef(null);
  const refDialogoAutorizacaoComQrcode = useRef(null);
  const refProcessamentoAberturaSessao = useRef(false)

  const [disabledCaixa, setDesabledCaixa] = useState(true);

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  useEffect(() => {
    async function getDataContas() {
      const itens = [];
      const dataCaixas = await getListAllAPI('contas', ['id', 'asc'], { is_caixa: true, status: STATUS_ATIVO }, []);

      for (let i = 0; i < dataCaixas.data.length; i += 1) {
        itens.push({
          id: dataCaixas.data[i].id,
          nome: dataCaixas.data[i].nome,
        });
        if (dataCaixas.data[i].id === getCaixaId()) {
          setCaixa({
            id: dataCaixas.data[i].id,
            nome: dataCaixas.data[i].nome,
          });
        }
      }
      setOptionsCaixas(itens);
    }
    getDataContas();
  }, []);

  useEffect(() => {
    async function getDataTiposCaixas() {
      const itens = [];
      const dataTiposCaixas = await getListAllAPI('categorias_frente_caixas', ['id', 'asc'], { }, []);

      let defaultTipoCaixa = null;

      for (let i = 0; i < dataTiposCaixas.data.length; i += 1) {
        itens.push({
          id: dataTiposCaixas.data[i].id,
          nome: dataTiposCaixas.data[i].nome,
        });
        if (dataTiposCaixas.data[i].id === getTipoCaixaId()) {
          defaultTipoCaixa = {
            id: dataTiposCaixas.data[i].id,
            nome: dataTiposCaixas.data[i].nome,
          };
        }
      }

      if (defaultTipoCaixa) {
        setTipoCaixa(defaultTipoCaixa);
        setTipoCaixaId(defaultTipoCaixa.id);
      } else {
        setTipoCaixa(itens[0]);
        setTipoCaixaId(itens[0].id);
      }

      setOptionsTiposCaixas(itens);
    }
    getDataTiposCaixas();
  }, []);

  async function handleSubmit(codigo, user_id_2, transportador_id) {
    if(!refProcessamentoAberturaSessao.current) {
      refProcessamentoAberturaSessao.current = true
      if (!isAuthenticated()) {
        setLoading(true);
        try {
          const dataUsuario = await api.put('/malote_sessoes/abrir', {
            caixa_id: getCaixaId(),
            username: usuario,
            password: senha,
            codigo,
            tipo_caixa_id: getTipoCaixaId(),
            responsavel2_id: user_id_2,
            transportador2_id: transportador_id
          });

          const {
            request_status,
            message,
            token,
            refreshToken,
            username,
            user_id,
            sessao_id,
            idTrocoAbertura,
            isNotaAbertura,
          } = dataUsuario.data;

          if(request_status) {

            login(token, refreshToken);
            setUsername(username);
            setUserId(user_id);
            setSessionId(sessao_id);

            if (isNotaAbertura) {
              const codigo2 = await imprimirRelatorioAberturaCaixa(idTrocoAbertura);

              if (codigo2 < 0) {
                enqueueSnackbar(
                  'Erro ao imprimir a nota de abertura do caixa!',
                  {
                    variant: 'error',
                  },
                );
              }
            }

            history.push('/');
          } else {
            enqueueSnackbar(
              message,
              {
                variant: 'error',
              },
            );
            if (refs.current[3].current) {
              refs.current[3].current.focus();
              refs.current[3].current.select();
            }
          }
        } catch (error) {
          enqueueSnackbar('Erro Inesperado!', {
            variant: 'error',
          });
          if (refs.current[3].current) {
            refs.current[3].current.focus();
            refs.current[3].current.select();
          }
        } finally {
          refProcessamentoAberturaSessao.current = false
          setLoading(false);
        }
      }
    }
  }

  async function getCategoriaCaixas() {
    const data = await api.get(`/categorias_frente_caixas/${getTipoCaixaId()}`);
    return data.data;
  }

  async function handlePreSubmit() {
    const { utilizar_troco_abertura_caixa } = await getCategoriaCaixas();
    if (utilizar_troco_abertura_caixa) {
      if (refDialogoPesquisarCodigo.current) {
        refDialogoPesquisarCodigo.current.handleOpen();
      }
    } else {
      handleSubmit('');
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        if (!erroExistente) {
          handlePreSubmit();
        }
        break;
      case MUDAR_CAIXA_ACTION:
        if (refDialogoAutorizacaoComQrcode.current) {
          if (disabledCaixa) refDialogoAutorizacaoComQrcode.current.handleOpen();
        }
        break;
      case RECONECTAR_SESSAO_ACTION:
        if (refDialogoReconectarSessao.current) {
          refDialogoReconectarSessao.current.handleOpen();
        }
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = disabledCaixa ? 1 : 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    const is = disabledCaixa ? position === 1 : position === 0
    if (is) {
      if(erroExistente) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      } else {
        handlePreSubmit()
      }
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function validateCaixa() {
    let error = '';
    if (!caixa) {
      error = 'É necessário fornecer o caixa!';
    }
    return error;
  }

  function validateTipoCaixa() {
    let error = '';
    if (!tipoCaixa) {
      error = 'É necessário fornecer o tipo de sessão!';
    }
    return error;
  }

  function validateUsuario() {
    let error = '';
    if (!usuario) {
      error = 'É necessário fornecer o nome de usuário!';
    }
    return error;
  }

  function validateSenha() {
    let error = '';
    if (!senha) {
      error = 'É necessário fornecer a senha do usuário!';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
      '',
      '',
      '',
    ];
    errosOld[0] = validateCaixa();
    errosOld[1] = validateTipoCaixa();
    errosOld[2] = validateUsuario();
    errosOld[3] = validateSenha();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function saveCaixaLocalStorage(value) {
    if (value) {
      setCaixaId(value.id);
    } else {
      setCaixaId(null);
    }
  }

  function saveTipoCaixaLocalStorage(value) {
    if (value) {
      setTipoCaixaId(value.id);
    } else {
      setTipoCaixaId(null);
    }
  }

  const versao = `Versão: ${APP_VERSION}`;

  return (
    <>
      {isAuthenticated() ? (
        <Redirect to="/" />
      ) : (
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            <div className={classes.hint}>CARUARU FRIOS</div>
            <div className={classes.hint}>MÓDULO DE FRENTE DE CAIXA</div>
            <div className={classes.hint}>{versao}</div>
            <div className={classes.form}>
              <div className={classes.input}>
                <AutoCompleteLocal
                  name={inputs[0].nome}
                  ref={refs.current[0]}
                  handleEnter={() => handleNextInput(0)}
                  label="Caixa"
                  handleKey={handleKey}
                  value={caixa}
                  onChangeLocal={(value) => {
                    setCaixa(value);
                    saveCaixaLocalStorage(value);
                  }}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  campo="nome"
                  options={optionsCaixas}
                  variant="standard"
                  disabled={disabledCaixa}
                />
              </div>
              <div className={classes.input}>
                <AutoCompleteLocal
                  name={inputs[1].nome}
                  ref={refs.current[1]}
                  handleEnter={() => handleNextInput(1)}
                  label="Tipo de Sessão"
                  handleKey={handleKey}
                  value={tipoCaixa}
                  onChangeLocal={(value) => {
                    setTipoCaixa(value);
                    saveTipoCaixaLocalStorage(value);
                  }}
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                  campo="nome"
                  options={optionsTiposCaixas}
                  variant="standard"
                />
              </div>
              <div className={classes.input}>
                <InputUsername
                  ref={refs.current[2]}
                  label="Usuário"
                  value={usuario}
                  onChange={(value) => setUsuario(value.target.value)}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  fullWidth
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) handleNextInput(2);
                    else handleKey(e.keyCode, e.key);
                  }}
                  disabled={loading}
                  autoFocus
                />
              </div>
              <div className={classes.input}>
                <InputPassaword
                  ref={refs.current[3]}
                  label="Senha"
                  value={senha}
                  onChange={(value) => setSenha(value.target.value)}
                  error={erros[3] !== ''}
                  helperText={erros[3]}
                  fullWidth
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) handleNextInput(3);
                    else handleKey(e.keyCode, e.key);
                  }}
                  disabled={loading}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={erroExistente}
                onClick={handlePreSubmit}
                tabindex="-1"
              >
                {loading && (
                  <CircularProgress
                    size={25}
                    thickness={2}
                    color="secondary"
                    style={{ marginRight: '10px' }}
                    tabindex="-1"
                  />
                )}
                {`Entrar (${FECHAR_VENDA_ACTION})`}
              </Button>
            </CardActions>
          </Card>
          <div style={{
            marginTop: '20px',
            display: 'flex',
            gap: '10px'
          }}>
            {disabledCaixa ? <Chip
              icon={<PersonalVideoIcon />}
              label={`Mudar Caixa (${MUDAR_CAIXA_ACTION})`}
              onClick={() => {
                if (refDialogoAutorizacaoComQrcode.current) {
                  if (disabledCaixa) refDialogoAutorizacaoComQrcode.current.handleOpen();
                }
              }}
              variant="outlined"
              color="primary"
              clickable
            /> : null}
            <Chip
              icon={<SyncIcon />}
              label={`Reconectar Sessão (${RECONECTAR_SESSAO_ACTION})`}
              onClick={() => {
                if (refDialogoReconectarSessao.current) {
                  refDialogoReconectarSessao.current.handleOpen();
                }
              }}
              variant="outlined"
              color="primary"
              clickable
            />
          </div>
        </div>
      )}
      {/*<DialogoAlteraCaixa
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          setDesabledCaixa(false);
        }}
        title="Autorização alterar caixa associado!"
      />*/}
      <DialogoAutorizacaoComQrcode
        ref={refDialogoAutorizacaoComQrcode}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id) => {
          setDesabledCaixa(false);
        }}
        mensagemSucesso='Autorizada a alteração do caixa associado!'
        title="Autorização alterar caixa associado!"
      />
      <DialogoPesquisaCodigo
        ref={refDialogoPesquisarCodigo}
        handleSalvar={handleSubmit}
      />
      <DialogoReconectarSessao
        ref={refDialogoReconectarSessao}
        handleFail={(message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }}
        handleSuccess={(data) => {
          const {
            caixaId,
            controleEstoqueRefreshToken,
            controleEstoqueToken,
            sessionId,
            tipoCaixaId,
            userId,
            username,
          } = data;
  
          login(controleEstoqueToken, controleEstoqueRefreshToken);
          setUsername(username);
          setUserId(userId);
          setSessionId(sessionId);
          setTipoCaixaId(tipoCaixaId);
          setCaixaId(caixaId);
          
          history.push('/');
        }}
        enqueueSnackbar={enqueueSnackbar}
      />
    </>
  );
};

export default LoginTela;
