import {
  createContext,
} from 'react';

export const AppContext = createContext({
  app: {
    saldoCaixa: 0,
    mensagemInicialFc: '',
    saldoIndicadorRetirada: 1500,
    meiosPagamento: [],
    limiteInferiorNotaCpf: 5000,
    limiteSuperiorNotaCpf: 10000,
    videosTutoriais: []
  },
  dispatch: (data) => {},
});
