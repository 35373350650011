import {
  getCaixaId,
  getListAllAPI,
} from '../services';

const applyMiddleware = (dispatch) => async (action) => {
  switch (action.type) {
    case 'UPDATE_SALDO_CAIXA': {
      const data = await getListAllAPI(
        'contas',
        ['id', 'asc'],
        { id: [getCaixaId()] },
        [],
      );
      dispatch({
        type: 'UPDATE_SALDO_CAIXA',
        saldoCaixa: data.data[0].saldoAtual,
      });
      break;
    }
    default:
      dispatch(action);
      break;
  }
};

export default applyMiddleware;
