import React, {
  useRef, useContext, useEffect, useState
} from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { makeStyles, Box, Paper } from '@material-ui/core';
import moment from 'moment';

import {
  DialogoDeletarComSenha,
  DialogoSenha,
  SearchProduto,
  LabelCaixa,
  LabelSubtotal,
  LabelTeclasAtalho,
  Tabela,
  DialogoConsultorPrecos,
  DialogoPedido,
  LabelCaixaria,
  DialogoSelecionarCliente
} from '../../../../components';
import { useItens, usePermissoes } from '../../../../hooks';
import {
  getListAllAPI,
  api,
  getSessionId,
  setVendaCancelada
} from '../../../../services';
import {
  toStringItem,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
  getNomeProduto,
  getTotal2,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  formatCPF,
  formatCNPJ,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
} from '../../../../utils';
import { VendaContext } from '../../context';
import FormItemVenda from './Form';
import DialogoDeletarItem from '../../../../components/Dialogos/DialogoDeletarItem'
import DialogoAutorizacaoComQrcode from '../../../../components/Dialogos/DialogoAutorizacaoComQrcode'
import DialogoCpfNota from './DialogoCpfNota'
import { AppContext } from '../../../../app/context';
import DialogoCarregarLiberacoes from './DialogoCarregarLiberacoes'

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  headerLivre: {
    height: '17%',
  },
  bodyLivre: {
    height: '83%',
  },
  rootProduto: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontSize: '32px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    opacity: '0.75',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const DELETAR_ITEM_ACTION = 'F2';
const CONSULTOR_PRECOS_ACTION = 'F3';
const CARREGAR_PEDIDO_ACTION = 'F10';
const MUDAR_CAIXARIA_ACTION = 'F7';
const ESC_ACTION = 'Escape';
const SELECIONAR_CLIENTE_ACTION = 'F11';
const colorHeader = SECONDARY_COLOR;
const CARREGAR_PRECOS_LIBERADOS_ACTION = 'F12';

function getFinal(data, days = 2) {
  return new Date(moment(data).add(days, 'days').startOf('day').format());
}

const FrenteVenda = ({
  irParaTelaInit,
  irParaTelaFinalizarVenda,
}) => {
  const { app: { limiteInferiorNotaCpf, limiteSuperiorNotaCpf } } = useContext(AppContext);
  const { venda: { itens, isEditPrice, cliente, isEmPartes, cpf }, dispatch } = useContext(VendaContext);
  const refDialogoCpfNota = useRef(null)
  const refDialogoSenha = useRef(null);
  const refDialogoDeletarItem = useRef(null);
  const refDialogoPedido = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoConsultorPrecos = useRef(null);
  const refDialogoAutorizacaoComQrcode = useRef(null);
  const refDialogoSelecionarCliente = useRef(null);

  const refDialogoCarregarLiberacoes = useRef(null);

  const {
    searchHandle,
    searchHandle2,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    changeCaixaria,
    setProduto,
    handleCarregarLiberacoes
  } = useItens(itens, updateItem, addItensLiberacao);
  const {
    consultarPrecosPermissao,
    carregarPedidoPermissao,
    pesquisaPorNomes,
    tipoCaixa,
  } = usePermissoes();

  const classes = useStyles();

  const nomeProduto = getNomeProduto(produto, 'NOVA VENDA');

  useEffect(() => {
    if (refDialogoSelecionarCliente.current) {
      refDialogoSelecionarCliente.current.handleOpen();
    }
  }, []);

  function updateItem(body) {
    dispatch({
      type: 'ADD_ITEM',
      item: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function addItensLiberacao(liberacoes) {
    dispatch({
      type: 'ADD_ITENS_LIBERACOES',
      liberacoes,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function removeItens(indices, responsavel_id) {
    dispatch({ type: 'REMOVE_ITEM', indices, responsavel_id });
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        if (itens.length > 0) {
          if(subTotal < limiteInferiorNotaCpf) {
            dispatch({ type: 'UPDATE_CPF', cpf: null });
            irParaTelaFinalizarVenda();
          } else {
            if(cpf) {
              irParaTelaFinalizarVenda();
            } else {
              if(subTotal >= limiteInferiorNotaCpf && subTotal < limiteSuperiorNotaCpf) {
                if (refDialogoCpfNota.current) { refDialogoCpfNota.current.handleOpen(true); }
              } else {
                if (refDialogoCpfNota.current) { refDialogoCpfNota.current.handleOpen(false); }
              }
            }
          }
        } else {
          enqueueSnackbar('É necessário ao menos um item na venda!', {
            variant: 'warning',
          });
        }
        break;
      case CANCELAR_VENDA_ACTION:
        //if (refDialogoSenha.current) { refDialogoSenha.current.handleOpen(); }
        if (refDialogoAutorizacaoComQrcode.current) { refDialogoAutorizacaoComQrcode.current.handleOpen(); }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (consultarPrecosPermissao) {
          if (refDialogoConsultorPrecos.current) {
            refDialogoConsultorPrecos.current.handleOpen();
          }
        }
        break;
      case CARREGAR_PEDIDO_ACTION:
        if (carregarPedidoPermissao) {
          if (refDialogoPedido.current) {
            refDialogoPedido.current.handleOpen();
          }
        }
        break;
      case DELETAR_ITEM_ACTION:
        if (itens.length > 0) {
          //if (refDialogoDeletar.current) { refDialogoDeletar.current.handleOpen(); }
          if (refDialogoDeletarItem.current) { refDialogoDeletarItem.current.handleOpen(); }
        }
        break;
      case MUDAR_CAIXARIA_ACTION:
        changeCaixaria()
        break;
      case ESC_ACTION:
        /*setProduto(null);
        setSearch('');
        if (refSearch.current) refSearch.current.focus();*/
        //if (refDialogoSenha.current) { refDialogoSenha.current.handleOpen(); }
        if (refDialogoAutorizacaoComQrcode.current) { refDialogoAutorizacaoComQrcode.current.handleOpen(); }
        break;
      case SELECIONAR_CLIENTE_ACTION:
        if (refDialogoSelecionarCliente.current) {
          refDialogoSelecionarCliente.current.handleOpen();
        }
        break;
      case CARREGAR_PRECOS_LIBERADOS_ACTION:
        if(refDialogoCarregarLiberacoes.current) {
          refDialogoCarregarLiberacoes.current.handleOpen()
        }
        break;
      default:
        break;
    }
  }

  function formatItens(itensOld) {
    const itensNew = [];
    for (let i = 0; i < itensOld.length; i += 1) {
      const unidades = itensOld[i].unidades_caixa_aparente 
        ? 
        itensOld[i].unidades_caixa_aparente 
        : 
        (itensOld[i].unidades_caixa 
          ? 
          (itensOld[i].unidades / itensOld[i].unidades_caixa) 
          : 
          itensOld[i].unidades
        )
      const unitario = itensOld[i].unidades_caixa_aparente 
        ? 
        itensOld[i].preco_venda_caixa 
        : 
        (itensOld[i].unidades_caixa 
          ? 
          (itensOld[i].preco_venda_caixa) 
          : 
          itensOld[i].preco_venda
        )

      const isNivel = itensOld[i].unidades_caixa_aparente
      ? true
      : !!itensOld[i].unidades_caixa;

      itensNew.push({
        produto: itensOld[i].produto,
        peso: itensOld[i].peso,
        total: getTotal2(
          itensOld[i].peso,
          unidades,
          unitario,
          {
            ...itensOld[i].produto,
            nivelCaixa: isNivel,
          },
        ),
        unidades: itensOld[i].unidades,
        unitario: itensOld[i].preco_venda,
        uidd: `${itensOld[i].produto.nome}${itensNew.length}`,
        id: itensOld[i].id,
        idIndicadorChange: itensOld[i].id,
        unidadesCaixa: itensOld[i].unidades_caixa,
        unitarioCaixa: itensOld[i].preco_venda_caixa,
        pesoCaixa: itensOld[i].peso_caixa,
        unidadesCaixaAparente: itensOld[i].unidades_caixa_aparente,
        nivelCaixaria: itensOld[i].nivel_caixaria,
        cgp: itensOld[i].nivel_caixaria,
        grupo_oferta_preco_produto_id: itensOld[i].grupo_oferta_preco_produto_id,
        tipo_oferta: itensOld[i].tipo_oferta,
        is_margem_cartao: itensOld[i].is_margem_cartao
      });
    }
    return itensNew;
  }

  async function handlePedido(id) {
    try {
      const data = await getListAllAPI(
        'pre_vendas',
        ['id', 'asc'],
        { id: [id] },
        ['itens.produto.unidade', 'cliente.cidade.estado', 'cliente.categoria'],
      );

      if (data.data.length <= 0) {
        throw 'Este pedido não existe!';
      }

      const pedido = data.data[0];

      if (pedido.is_utilizado) {
        throw 'Este pedido já foi utilizado!';
      }

      if (getFinal(pedido.created_at).getTime() < new Date().getTime()) {
        throw 'Este pedido já passou do tempo limite para ser utilizado!';
      }

      await api.put(`/pre-vendas/marcar-como-utilizada/${id}`);

      dispatch({
        type: 'PREENCHER_TUDO_PEDIDO',
        itens: formatItens(pedido.itens),
        cliente: pedido.cliente,
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  const isCaixaria = produto ? (produto.unidades_caixa_nivel_1 > 0 || produto.peso_caixa_nivel_1 > 0) : false

  function getLabelsAtalhos() {
    const labelsAtalhos = [];

    labelsAtalhos.push({ atalho: DELETAR_ITEM_ACTION, label: 'Remover item', status: itens.length > 0 });
    if (consultarPrecosPermissao) labelsAtalhos.push({ atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' });
    labelsAtalhos.push({ atalho: CANCELAR_VENDA_ACTION, label: 'Voltar' });
    labelsAtalhos.push({ atalho: MUDAR_CAIXARIA_ACTION, label: 'Caixaria', status: isCaixaria });
    labelsAtalhos.push({ atalho: FECHAR_VENDA_ACTION, label: 'Concluir' });
    if (carregarPedidoPermissao) labelsAtalhos.push({ atalho: CARREGAR_PEDIDO_ACTION, label: 'Car. Pedido' });
    labelsAtalhos.push({ atalho: SELECIONAR_CLIENTE_ACTION, label: 'Cliente' });
    labelsAtalhos.push({ atalho: CARREGAR_PRECOS_LIBERADOS_ACTION, label: 'Car. Liber.' });

    if (labelsAtalhos.length <= 4) {
      return [
        labelsAtalhos,
        [],
      ];
    }
    return [
      labelsAtalhos.slice(0, 4),
      labelsAtalhos.slice(4),
    ];
  }

  function interceptadorSearchHandle(codigo, id) {
    searchHandle(codigo, id)
  }

  function montarObjVendaNaoFinalizada() {
    const listaItens = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_venda: itens[i].unitario,
        preco_compra: itens[i].produto.precoCompraMedio,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        preco_venda_caixa: itens[i].unitarioCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
      });
    }

    return {
      listaItens,
      cliente_id: cliente.id,
      sessao_id: getSessionId(),
      is_em_partes: isEmPartes,
    };
  }

  async function registraVendaNaoFinalizada(responsavel_id) {
    try {
      const objVenda = montarObjVendaNaoFinalizada();
      const data = await api.post('/vendas_excluidas', {
        ...objVenda,
        responsavel_id
      });
      setVendaCancelada(null)
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  async function handleCancelar(responsavel_id) {
    await registraVendaNaoFinalizada(responsavel_id)
    irParaTelaInit();
  }

  async function getClientePorCpf(cpf) {
    try {
      const dataClienteCpf = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { cpf, status: STATUS_ATIVO },
        ['cidade.estado', 'categoria'],
      );

      return dataClienteCpf.data.length > 0 ? dataClienteCpf.data[0] : null
    } catch(e) {
      return null
    }
  }

  function hasLiberacao(itens) {
    let hasLiberacaoValue = false
    for(let i = 0; i < itens.length; i++) {
      if(itens[i].tipo_oferta === TIPO_OFERTA_PRODUTO_LIBERACAO) {
        hasLiberacaoValue = true
      }
    }
    return hasLiberacaoValue
  }

  const hasLiberacaoValue = hasLiberacao(itens)

  return (
    <>
      {/*<Box
        padding="10px"
        className={classes.header}
      >
        <Box
          display="flex"
          css={{
            backgroundColor: 'white', opacity: '0.75', height: '100%', padding: '0px 10px', flexDirection: 'column',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flex={1}
          >
            {cliente && (
            <Box
              display="flex"
              justifyContent="space-between"
              flex={1}
              style={{
                border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '10px', padding: '5px', borderRadius: '5px',
              }}
            >
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Responsável:</p>
                  {cliente.nome}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Razão Social:</p>
                  {cliente.razao_social}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>CPF:</p>
                  {formatCPF(cliente.cpf)}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>CNPJ:</p>
                  {formatCNPJ(cliente.cnpj)}
                </Box>
              </Box>
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Nome Fantasia:</p>
                  {cliente.nome_fantasia}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Bairro:</p>
                  {cliente.bairro}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Cidade:</p>
                  {cliente.cidade ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}` : ''}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Logradouro:</p>
                  {cliente.logradouro}
                </Box>
              </Box>
            </Box>
            )}
          </Box>
        </Box>
            </Box>*/}
      <Box padding="10px" className={classes.header}>
        <Box margin="0px 0px 10px">
          <Paper className={classes.rootProduto}>{nomeProduto}</Paper>
        </Box>
        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={interceptadorSearchHandle}
          searchHandle2={searchHandle2}
          ref={refSearch}
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          filters={{}}
          //filters={pesquisaPorNomes ? {  status: STATUS_ATIVO } : { is_search: true, status: STATUS_ATIVO }}
          resource = 'produtos/search/autocomplete2'
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          overflow="auto"
        >
          <Box>
            <LabelCaixa tipoCaixa={tipoCaixa} />
            <Box mt="15px">
              <LabelSubtotal valor={subTotal} />
            </Box>
            { (produto) && (
            <Box mt="15px">
              <LabelCaixaria produto={produto} />
            </Box>
            )}
          </Box>
          <LabelTeclasAtalho
            fontSize="13px"
            grid={getLabelsAtalhos()}
          />
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={toStringItem(itens)}
            headCells={[
              {
                field: 'numeroItem', label: 'Cód.', handleColor: (row) => {
                  if(row.tipo_oferta === TIPO_OFERTA_PRODUTO_LIBERACAO) {
                    return 'red'
                  }
                  return 'black'
                }
              },
              {
                field: 'produto',
                label: 'Produto',
              },
              {
                field: 'unidades', right: true, label: 'Unidades',
              },
              {
                field: 'peso', right: true, label: 'Peso (Kg)',
              },
              {
                field: 'unitario', right: true, label: 'Unitário',
              },
              {
                field: 'total', right: true, label: 'SubTotal',
              },
            ]}
          />
        </Box>
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          overflow="auto"
        >
          <FormItemVenda
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            editPrice={isEditPrice}
            produto={produto}
            tipoTransacao="venda"
            handleNewItemCodigo={(dados) => {
              const {codigo, ...body} = dados
              addNewItem(body)
              searchHandle(codigo)
            }}
          />
        </Box>
      </Box>
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          handleCancelar()
        }}
        title="Autorização cancelamento de venda!"
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={itens}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([itens[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
      />
      <DialogoDeletarItem
        ref={refDialogoDeletarItem}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(codigo, user_id) => {
          if(codigo.length >= 6) {
            const index = itens.findLastIndex(item => {
              let codigoEfetivo = ''
              switch (item.nivelCaixaria) {
                case 0:
                  codigoEfetivo = item.produto.codigo
                  break;
                case 1:
                  codigoEfetivo = item.produto.codigo_caixa_nivel_1
                  break;
                case 2:
                  codigoEfetivo = item.produto.codigo_caixa_nivel_2
                  break;
                case 3:
                  codigoEfetivo = item.produto.codigo_caixa_nivel_3
                  break;
                default:
                  codigoEfetivo = item.produto.codigo
                  break;
              }
              return codigoEfetivo === codigo
            })
            if(index !== undefined) {
              removeItens([itens[index].uidd], user_id);
            }
          } else {
            const position = parseInt(codigo, 10) - 1
            if(position <= itens.length - 1) {
              removeItens([itens[position].uidd], user_id);
              enqueueSnackbar('Item removido com sucesso!', {
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Não existe um item com este código!', {
                variant: 'error',
              });
            }
          }
        }}
        title="Remover Item!"
        mensagemRemovido="Item Removido com Sucesso!"
        titleAutorizacao="Autorizar remoção de item"
        mensagemRemovidoAutorizacao="Autorizada a remoção do item!"
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
      <DialogoPedido ref={refDialogoPedido} handlePedido={handlePedido} />
      <KeyboardEventHandler
        handleKeys={[
          FECHAR_VENDA_ACTION,
          CANCELAR_VENDA_ACTION,
          DELETAR_ITEM_ACTION,
          CONSULTOR_PRECOS_ACTION,
          CARREGAR_PEDIDO_ACTION,
          MUDAR_CAIXARIA_ACTION,
          SELECIONAR_CLIENTE_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoAutorizacaoComQrcode
        ref={refDialogoAutorizacaoComQrcode}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id) => {
          handleCancelar(user_id)
        }}
        mensagemSucesso={'Venda cancelada com sucesso!'}
        title="Autorização cancelamento de venda!"
      />
      <DialogoCpfNota ref={refDialogoCpfNota} handleProsseguir={async (cpf) => {
        dispatch({ type: 'UPDATE_CPF', cpf });
        if(cpf) {
          const cliente = await getClientePorCpf(cpf)
          if(cliente) {
            dispatch({ type: 'UPDATE_CLIENTE', cliente: cliente });
          }
        }
        irParaTelaFinalizarVenda();
      }}/>
      <DialogoSelecionarCliente
        handleSelecionado={() => {}}
        handleTerminado={() => {
          if (refSearch.current) refSearch.current.focus();
        }}
        handleFechado={(cliente) => {
          if (refSearch.current) refSearch.current.focus();
          if(cliente) {
            dispatch({ type: 'UPDATE_CLIENTE', cliente });
          }
        }}
        dispatch={dispatch}
        ref={refDialogoSelecionarCliente}
        clienteInicial={cliente}
        hasLiberacaoValue={hasLiberacaoValue}
      />
      <DialogoCarregarLiberacoes 
        ref={refDialogoCarregarLiberacoes} 
        handleCarregarLiberacoes={handleCarregarLiberacoes} 
        cliente={cliente}
      />
    </>
  );
};

export default FrenteVenda;
