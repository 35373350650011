import React, {
  useReducer,
  useEffect,
  useState
} from 'react';
import {
  BrowserRouter as Router, Route,
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import PreVendas from '../Pages/CreatePreVenda';
import Vendas from '../Pages/CreateVenda';
import InitPage from '../Pages/Init';
import LoginPage from '../Pages/Login';
import theme from '../themes/default';
import { AppContext } from './context';
import applyMiddleware from './middleware';
import reducer from './reducer';
import RouteBackground from './RouteBackground';
import { APP_VERSION } from '../utils'
import { api, getListAllAPI } from '../services'

function MensagemDesatualizado({ oldVersion, newVersion }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: 'red',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        FC desatualizado, se quer voltar a usa-lo, por favor abre e feche
        algumas vezes até atualizar
      </h1>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Atual: ${oldVersion}`}</h2>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Nova: ${newVersion}`}</h2>
    </div>
  );
}

function App() {
  return (
    <>
      <RouteBackground exact path="/" component={InitPage} isInit />
      <RouteBackground path="/vendas" component={Vendas} />
      <RouteBackground path="/pre_vendas" component={PreVendas} />
      <Route path="/login" component={LoginPage} />
    </>
  );
}

function WapperApp() {
  const [app, dispatch] = useReducer(reducer, {
    saldoCaixa: 0,
    mensagemInicialFc: ''
  });
  const [version, setVersion] = useState(APP_VERSION);

  async function getVersion() {
    const response = await api.get('/versions');
    const { fc } = response.data;

    setVersion(fc);
  }

  useEffect(() => {
    getVersion();
  }, []);

  async function getMensagemInicialFc() {
    const response = await api.get('/config/1');
    const { 
      mensagem_tela_inicial_fc, 
      saldo_indicador_retirada ,
      limite_inferior_nota_cpf,
      limite_superior_nota_cpf
    } = response.data;

    dispatch({ type: 'UPDATE_MENSAGEM_TELA_INICIAL_FC', mensagemInicialFc: mensagem_tela_inicial_fc })
    dispatch({ type: 'UPDATE_SALDO_INDICADOR_RETIRADA', saldoIndicadorRetirada: saldo_indicador_retirada })
    dispatch({ 
      type: 'UPDATE_LIMITES_CPF_NOTA', 
      limiteInferiorNotaCpf: limite_inferior_nota_cpf,
      limiteSuperiorNotaCpf: limite_superior_nota_cpf,
    })
  }

  useEffect(() => {
    getMensagemInicialFc();
  }, []);

  async function getMeioPagamentos() {
    const dataTiposPagamento = await getListAllAPI(
      'tipospagamento',
      ['id', 'asc'],
      { ativo: true },
      [],
    );

    dispatch({ type: 'UPDATE_MEIOS_PAGAMENTO', meiosPagamento: dataTiposPagamento.data })
  }

  useEffect(() => {
    getMeioPagamentos();
  }, []);

  async function getVideosTutoriais() {
    const dataVideosTutoriais = await getListAllAPI(
      'videos_tutoriais_fc',
      ['data_postagem', 'desc'],
      {  },
      [],
    );

    dispatch({ type: 'UPDATE_VIDEOS_TUTORIAIS', videosTutoriais: dataVideosTutoriais.data })
  }

  useEffect(() => {
    getVideosTutoriais();
  }, []);

  const isAtualizado = version === APP_VERSION;

  return (
    <>
      {isAtualizado ? (
        <div className="App" style={{ height: '100vh', width: '100vw' }}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={6}>
              <AppContext.Provider value={{ app, dispatch: applyMiddleware(dispatch) }}>
                <Router>
                  <App />
                </Router>
              </AppContext.Provider>
            </SnackbarProvider>
          </ThemeProvider>
        </div>
      ) : (
        <MensagemDesatualizado oldVersion={APP_VERSION} newVersion={version} />
      )}
    </>
  );
}

export default WapperApp;
