import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  createAPI, updateAPI, getListAllAPI,
} from '../../../services';
import {
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
} from '../../../utils';

import {
  DialogoSenha,
} from '..';

import {
  AutoCompleteRemoto,
  CepInput,
  CnpjInput,
  CpfInput,
  DateInput,
  IEInput,
  TelefoneInput,
  TextInput,
} from '../../Inputs';

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
//const MUDAR_FIDELIDADE_ACTION = 'F10';
const ESC_ACTION = 'Escape';

function validateEmail2(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const NOME_POSITION = 0;
const CPF_POSITION = 1;
const TELEFONE_POSITION = 2;
const EMAIL_POSITION = 3;
const ANIVERSARIO_POSITION = 4;
const RG_POSITION = 5;
const CATEGORIA_POSITION = 6;
const RAZAO_SOCIAL_POSITION = 7;
const NOME_FANTASIA_POSITION = 8;
const CNPJ_POSITION = 9;
const IE_POSITION = 10;
const CIDADE_POSITION = 11;
const BAIRRO_POSITION = 12;
const LOGRADOURO_POSITION = 13;
const NUMERO_POSITION = 14;
const CEP_POSITION = 15;

const DEFAULT_ID = -1;

const SidebarInputs = forwardRef(({
  handleClose,
  user_id,
}, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [rg, setRg] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [aniversario, setAniversario] = useState('');
  const [cidade, setCidade] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [categoriaDefault, setCategoriaDefault] = useState(null);
  const [isFidelidade, setIsFidelidade] = useState(false);

  //const refDialogoSenha = useRef();

  const refs = useRef([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    async function getCategoriaDefault() {
      try {
        const data = await getListAllAPI(
          'categorias_cliente',
          ['id', 'asc'],
          { id: [1] },
          [],
        );
        if (data.data.length > 0) {
          setCategoriaDefault(data.data[0]);
        } else {
          throw 'Erro ao obter a categoria de cliente padrão!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    }
    getCategoriaDefault();
  }, []);

  const inputs = [
    {
      nome: 'nome222',
      defaultValue: '',
      label: 'Nome',
    },
    {
      nome: 'cpf222',
      defaultValue: '',
      label: 'CPF',
    },
    {
      nome: 'telefone222',
      defaultValue: '',
      label: 'Telefone',
    },
    {
      nome: 'bairro222',
      defaultValue: '',
      label: 'Bairro',
    },
    {
      nome: 'logradouro222',
      defaultValue: '',
      label: 'Logradouro',
    },
    {
      nome: 'numero222',
      defaultValue: '',
      label: 'Número',
    },
    {
      nome: 'rg222',
      defaultValue: '',
      label: 'RG',
    },
    {
      nome: 'razao_social222',
      defaultValue: '',
      label: 'Razão Social',
    },
    {
      nome: 'nome_fantasia222',
      defaultValue: '',
      label: 'Nome Fantasia',
    },
    {
      nome: 'cnpj222',
      defaultValue: '',
      label: 'CNPJ',
    },
    {
      nome: 'email222',
      defaultValue: '',
      label: 'Email',
    },
    {
      nome: 'cep222',
      defaultValue: '',
      label: 'CEP',
    },
    {
      nome: 'aniversario222',
      defaultValue: '',
      label: 'Aniversário',
    },
    {
      nome: 'cidade222',
      defaultValue: null,
      label: 'Cidade',
      resource: 'cidades',
      nested: ['estado'],
      filters: { ativo: true },
    },
    {
      nome: 'ie222',
      defaultValue: '',
      label: 'Inscrição Estadual',
    },
    {
      nome: 'Categoria222',
      defaultValue: null,
      label: 'Categoria',
      resource: 'categorias_cliente',
      nested: [],
      filters: { ativo: true },
    },
  ];

  refs.current = inputs.map(
    (ref, index) => refs.current[index] = React.createRef(),
  );

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) { error = 'Número de caracteres maior que o permitido (máximo 80)'; }
    return error;
  }

  function validateCpf() {
    let error = '';
    if (!cpf && !cnpj) error = 'Preencha este campo ou o cnpj!';
    else if (cpf) {
      if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
    }
    return error;
  }

  function validateTelefone() {
    let error = '';
    if (telefone) {
      if (telefone.length < 10) { error = 'O Telefone deve ter 10 ou 11 dígitos'; }
    }
    return error;
  }

  function validateBairro() {
    let error = '';
    if (bairro) {
      if (bairro.length > 80) { error = 'Número de caracteres maior que o permitido (máximo 80)'; }
    }
    return error;
  }

  function validateLogradouro() {
    let error = '';
    if (logradouro) {
      if (logradouro.length > 80) { error = 'Número de caracteres maior que o permitido (máximo 80)'; }
    }
    return error;
  }

  function validateNumero() {
    let error = '';
    if (numero) {
      if (numero.length > 6) { error = 'Máximo de 6 letras'; }
    }
    return error;
  }

  function validateRg() {
    let error = '';
    if (rg) {
      if (rg.length > 20) { error = 'Número de caracteres maior que o permitido (máximo 20)'; }
    }
    return error;
  }

  function validateRazaoSocial() {
    let error = '';
    if (razaoSocial) {
      if (razaoSocial.length > 80) { error = 'Número de caracteres maior que o permitido (máximo 80)'; }
    }
    return error;
  }

  function validateNomeFantasia() {
    let error = '';
    if (nomeFantasia) {
      if (nomeFantasia.length > 80) { error = 'Número de caracteres maior que o permitido (máximo 80)'; }
    }
    return error;
  }

  function validateCnpj() {
    let error = '';
    if (!cpf && !cnpj) error = 'Preencha este campo ou o cpf!';
    else if (cnpj) {
      if (cnpj.length < 14) error = 'O CNPJ deve ter 14 dígitos';
    }
    return error;
  }

  function validateEmail() {
    let error = '';
    if (email) {
      if (!validateEmail2(email)) error = 'Email Inválido';
      if (email.length > 80) { error = 'Número de caracteres maior que o permitido (máximo 80)'; }
    }
    return error;
  }

  function validateCep() {
    let error = '';
    if (cep) {
      if (cep.length < 8) error = 'O CEP deve ter 8 dígitos';
    }
    return error;
  }

  function validateAniversario() {
    const error = '';
    return error;
  }

  function validateCidade() {
    let error = '';
    if (!cidade) error = 'Este campo é obrigatório';
    return error;
  }

  function validateIE() {
    let error = '';
    if (ie) {
      if (ie.length >= 20) error = 'A Inscrição Estadual deve ser menor que 20 dígitos';
    }
    return error;
  }

  function validateCategoria() {
    let error = '';
    if (!categoria) error = 'Este campo é obrigatório';
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    errosOld[0] = validateNome();
    errosOld[1] = validateCpf();
    errosOld[2] = validateTelefone();
    errosOld[3] = validateBairro();
    errosOld[4] = validateLogradouro();
    errosOld[5] = validateNumero();
    errosOld[6] = validateRg();
    errosOld[7] = validateRazaoSocial();
    errosOld[8] = validateNomeFantasia();
    errosOld[9] = validateCnpj();
    errosOld[10] = validateEmail();
    errosOld[11] = validateCep();
    errosOld[12] = validateAniversario();
    errosOld[13] = validateCidade();
    errosOld[14] = validateIE();
    errosOld[15] = validateCategoria();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function decompoeData(data) {
    if (data === null || data === '') {
      return {
        dia_aniversario: null,
        mes_aniversario: null,
        ano_aniversario: null,
      };
    }
    const dataAniversarioObj = new Date(moment(new Date(data)).add(1, 'days').format());
    const dia_aniversario = dataAniversarioObj.getDate();
    const mes_aniversario = dataAniversarioObj.getMonth();
    const ano_aniversario = dataAniversarioObj.getFullYear();
    return {
      dia_aniversario,
      mes_aniversario,
      ano_aniversario,
    };
  }

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome(inputs[0].defaultValue);
    setCpf(inputs[1].defaultValue);
    setTelefone(inputs[2].defaultValue);
    setBairro(inputs[3].defaultValue);
    setLogradouro(inputs[4].defaultValue);
    setNumero(inputs[5].defaultValue);
    setRg(inputs[6].defaultValue);
    setRazaoSocial(inputs[7].defaultValue);
    setNomeFantasia(inputs[8].defaultValue);
    setCnpj(inputs[9].defaultValue);
    setEmail(inputs[10].defaultValue);
    setCep(inputs[11].defaultValue);
    setAniversario(inputs[12].defaultValue);
    setCidade(inputs[13].defaultValue);
    setIe(inputs[14].defaultValue);
    setCategoria(categoriaDefault);
    setIsFidelidade(false);
    setCarregando(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { id: [id] },
        ['cidade.estado', 'categoria'],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCpf(data.data[0].cpf);
        setTelefone(data.data[0].telefone);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setRg(data.data[0].rg);
        setRazaoSocial(data.data[0].razao_social);
        setNomeFantasia(data.data[0].nome_fantasia);
        setCnpj(data.data[0].cnpj);
        setEmail(data.data[0].email);
        setCep(data.data[0].cep);
        setAniversario(moment(new Date(data.data[0].dataAniversario)).format('YYYY-MM-DD'));
        setCidade(data.data[0].cidade);
        setIe(data.data[0].inscricao_estadual);
        setCategoria(data.data[0].categoria);
        setIsFidelidade(data.data[0].is_fidelidade);
        setCarregando(false);
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const clienteCriado = await createAPI(
            'clientes',
            {
              nome,
              cpf,
              telefone,
              bairro,
              logradouro,
              numero,
              rg,
              razao_social: razaoSocial,
              nome_fantasia: nomeFantasia,
              cnpj,
              email,
              cep,
              cidade_id: cidade ? cidade.id : null,
              categoria_cliente_id: categoria ? categoria.id : null,
              user_id,
              is_fidelidade: isFidelidade,
              ...decompoeData(aniversario),
            },
          );
          setCarregando(false);
          enqueueSnackbar('Cliente criado com sucesso!', {
            variant: 'success',
          });
          idLocal = clienteCriado.data ? clienteCriado.data.id : -1;
        } else {
          await updateAPI(
            'clientes',
            id,
            {
              nome,
              cpf,
              telefone,
              bairro,
              logradouro,
              numero,
              rg,
              razao_social: razaoSocial,
              nome_fantasia: nomeFantasia,
              cnpj,
              email,
              cep,
              cidade_id: cidade ? cidade.id : null,
              categoria_cliente_id: categoria ? categoria.id : null,
              is_fidelidade: isFidelidade,
              ...decompoeData(aniversario),
            },
          );
          setCarregando(false);
          enqueueSnackbar('Cliente atualizado com sucesso!', {
            variant: 'success',
          });
          idLocal = id;
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setOpen(true);
      setCarregando(true);
      reiniciar();
    },
    handleEdit(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      /*case MUDAR_FIDELIDADE_ACTION:
        if (!isFidelidade) {
          if (refDialogoSenha.current) {
            refDialogoSenha.current.handleOpen();
          }
        }
        break;*/
      case ESC_ACTION:
        handleCloseDialog(-1);
        break;
      default:
        break;
    }
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        position = 6;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 10;
        break;
      case 10:
        position = 11;
        break;
      case 11:
        position = 12;
        break;
      case 12:
        position = 13;
        break;
      case 13:
        position = 14;
        break;
      case 14:
        position = 15;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title" style={{ color: 'white', backgroundColor: SECONDARY_COLOR, textAlign: 'center' }}>
        {id > 0 ? 'Editar Cliente' : 'Novo Cliente'}
      </DialogTitle>
      <DialogContent
        dividers
      >
        {/*<div style={{
          color: isFidelidade ? '#00e604' : '#e60000',
          backgroundColor: isFidelidade ? '#ccffcd' : '#ffcccc',
        }}
        >
          { isFidelidade ? 'Cliente Fidelidade' : `Este Cliente não é de Fidelidade, se quiser alterar aperte ${MUDAR_FIDELIDADE_ACTION}`}
      </div>*/}
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px',
              }}
              >
                <CircularProgress />
              </div>
            )
              : (
                <Box p="1em">
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h6" gutterBottom>
                        Dados Pessoais
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[0].nome}
                            ref={refs.current[NOME_POSITION]}
                            handleEnter={() => handleNextInput(NOME_POSITION)}
                            label={inputs[0].label}
                            handleKey={handleKey}
                            value={nome}
                            onChange={(value) => setNome(value.target.value)}
                            error={erros[0] !== ''}
                            helperText={erros[0]}
                            fullWidth
                            autoFocus
                            autoCompleteState="new-password"
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CpfInput
                            name={inputs[1].nome}
                            ref={refs.current[CPF_POSITION]}
                            handleEnter={() => handleNextInput(CPF_POSITION)}
                            label={inputs[1].label}
                            handleKey={handleKey}
                            value={cpf}
                            onChange={(value) => setCpf(value.target.value)}
                            error={erros[1] !== ''}
                            helperText={erros[1]}
                            fullWidth
                            autoCompleteState="new-password"
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TelefoneInput
                            name={inputs[2].nome}
                            ref={refs.current[TELEFONE_POSITION]}
                            handleEnter={() => handleNextInput(TELEFONE_POSITION)}
                            label={inputs[2].label}
                            handleKey={handleKey}
                            value={telefone}
                            onChange={(value) => setTelefone(value.target.value)}
                            error={erros[2] !== ''}
                            helperText={erros[2]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[10].nome}
                            ref={refs.current[EMAIL_POSITION]}
                            handleEnter={() => handleNextInput(EMAIL_POSITION)}
                            label={inputs[10].label}
                            handleKey={handleKey}
                            value={email}
                            onChange={(value) => setEmail(value.target.value)}
                            error={erros[10] !== ''}
                            helperText={erros[10]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <DateInput
                            name={inputs[12].nome}
                            ref={refs.current[ANIVERSARIO_POSITION]}
                            handleEnter={() => handleNextInput(ANIVERSARIO_POSITION)}
                            label={inputs[12].label}
                            handleKey={handleKey}
                            value={aniversario}
                            onChange={(value) => setAniversario(value.target.value)}
                            error={erros[12] !== ''}
                            helperText={erros[12]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[6].nome}
                            ref={refs.current[RG_POSITION]}
                            handleEnter={() => handleNextInput(RG_POSITION)}
                            label={inputs[6].label}
                            handleKey={handleKey}
                            value={rg}
                            onChange={(value) => setRg(value.target.value)}
                            error={erros[6] !== ''}
                            helperText={erros[6]}
                            fullWidth
                            autoCompleteState="new-password"
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <AutoCompleteRemoto
                            name={inputs[15].nome}
                            ref={refs.current[CATEGORIA_POSITION]}
                            handleEnter={() => handleNextInput(CATEGORIA_POSITION)}
                            resource={inputs[15].resource}
                            nested={inputs[15].nested}
                            filters={inputs[15].filters}
                            label={inputs[15].label}
                            handleKey={handleKey}
                            value={categoria}
                            onChange={(value) => setCategoria(value)}
                            error={erros[15] !== ''}
                            helperText={erros[15]}
                            toString={false}
                            fullWidth
                            getOptionSelected={(option, value) => option.nome === value.nome}
                            getOptionLabel={(option) => `${option.nome}`}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>

                      <Box mt="1em" />

                      <Typography variant="h6" gutterBottom>
                        Dados Empresariais
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[7].nome}
                            ref={refs.current[RAZAO_SOCIAL_POSITION]}
                            handleEnter={() => handleNextInput(RAZAO_SOCIAL_POSITION)}
                            label={inputs[7].label}
                            handleKey={handleKey}
                            value={razaoSocial}
                            onChange={(value) => setRazaoSocial(value.target.value)}
                            error={erros[7] !== ''}
                            helperText={erros[7]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[8].nome}
                            ref={refs.current[NOME_FANTASIA_POSITION]}
                            handleEnter={() => handleNextInput(NOME_FANTASIA_POSITION)}
                            label={inputs[8].label}
                            handleKey={handleKey}
                            value={nomeFantasia}
                            onChange={(value) => setNomeFantasia(value.target.value)}
                            error={erros[8] !== ''}
                            helperText={erros[8]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CnpjInput
                            name={inputs[9].nome}
                            ref={refs.current[CNPJ_POSITION]}
                            handleEnter={() => handleNextInput(CNPJ_POSITION)}
                            label={inputs[9].label}
                            handleKey={handleKey}
                            value={cnpj}
                            onChange={(value) => setCnpj(value.target.value)}
                            error={erros[9] !== ''}
                            helperText={erros[9]}
                            fullWidth
                            autoCompleteState="new-password"
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <IEInput
                            name={inputs[14].nome}
                            ref={refs.current[IE_POSITION]}
                            handleEnter={() => handleNextInput(IE_POSITION)}
                            label={inputs[14].label}
                            handleKey={handleKey}
                            value={ie}
                            onChange={(value) => setIe(value.target.value)}
                            error={erros[14] !== ''}
                            helperText={erros[14]}
                            fullWidth
                            autoCompleteState="new-password"
                          />
                        </Box>
                      </Box>

                      <Box mt="1em" />

                      <Typography variant="h6" gutterBottom>
                        Endereço
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <AutoCompleteRemoto
                            name={inputs[13].nome}
                            ref={refs.current[CIDADE_POSITION]}
                            handleEnter={() => handleNextInput(CIDADE_POSITION)}
                            resource={inputs[13].resource}
                            nested={inputs[13].nested}
                            filters={inputs[13].filters}
                            label={inputs[13].label}
                            handleKey={handleKey}
                            value={cidade}
                            onChange={(value) => setCidade(value)}
                            error={erros[13] !== ''}
                            helperText={erros[13]}
                            toString={false}
                            fullWidth
                            getOptionSelected={(option, value) => option.nome === value.nome}
                            getOptionLabel={(option) => `${option.nome} - ${option.estado.sigla}`}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[3].nome}
                            ref={refs.current[BAIRRO_POSITION]}
                            handleEnter={() => handleNextInput(BAIRRO_POSITION)}
                            label={inputs[3].label}
                            handleKey={handleKey}
                            value={bairro}
                            onChange={(value) => setBairro(value.target.value)}
                            error={erros[3] !== ''}
                            helperText={erros[3]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputs[4].nome}
                            ref={refs.current[LOGRADOURO_POSITION]}
                            handleEnter={() => handleNextInput(LOGRADOURO_POSITION)}
                            label={inputs[4].label}
                            handleKey={handleKey}
                            value={logradouro}
                            onChange={(value) => setLogradouro(value.target.value)}
                            error={erros[4] !== ''}
                            helperText={erros[4]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputs[5].nome}
                            ref={refs.current[NUMERO_POSITION]}
                            handleEnter={() => handleNextInput(NUMERO_POSITION)}
                            label={inputs[5].label}
                            handleKey={handleKey}
                            value={numero}
                            onChange={(value) => setNumero(value.target.value)}
                            error={erros[5] !== ''}
                            helperText={erros[5]}
                            fullWidth
                            autoCompleteState="new-password"
                          />

                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CepInput
                            name={inputs[11].nome}
                            ref={refs.current[CEP_POSITION]}
                            handleEnter={() => handleNextInput(CEP_POSITION)}
                            label={inputs[11].label}
                            handleKey={handleKey}
                            value={cep}
                            onChange={(value) => setCep(value.target.value)}
                            error={erros[11] !== ''}
                            helperText={erros[11]}
                            fullWidth
                            autoCompleteState="new-password"
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => handleCloseDialog(-1)}
          color="secondary"
          variant="contained"
          tabindex="-1"
        >
          {`Cancelar (${CANCELAR_VENDA_ACTION})`}
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            tabindex="-1"
          >
            {`Cadastrar (${FECHAR_VENDA_ACTION})`}
          </Button>
          {carregando && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
      {/*<DialogoSenha
        ref={refDialogoSenha}
        handleCancelar={() => {}}
        handleSalvar={() => {
          setIsFidelidade(true);
        }}
        title={isFidelidade ? 'Cancelar Fidelidade' : 'Começar Fidelidade'}
        enqueueSnackbar={enqueueSnackbar}
        mensagemSucesso={isFidelidade ? 'Cancelamento de fidelidade autorizada' : 'Fidelidade autorizada'}
      />*/}
    </Dialog>
  );
});

export default SidebarInputs;
