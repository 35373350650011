import moment from 'moment';

import {
  trunc10,
} from './arredondamentos';
import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  A_VISTA,
} from './constants';
import {
  formatMoeda,
  formatPeso,
} from './formats';
import { getLabelNivelCaixaria} from './precoVenda'

export function disablePeso(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) return false;
    return true;
  }
  return false;
}

export function disableUnidades(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return false;
    return true;
  }
  return false;
}

export function toStringItem(rows) {
  const rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    const unidades = rows[i].unidadesCaixaAparente 
        ? 
        rows[i].unidadesCaixaAparente 
        : 
        (rows[i].unidadesCaixa 
          ? 
          (rows[i].unidades / rows[i].unidadesCaixa) 
          : 
          rows[i].unidades
        )
      const unitario = rows[i].unidadesCaixaAparente 
        ? 
        rows[i].unitarioCaixa 
        : 
        (rows[i].unidadesCaixa 
          ? 
          (rows[i].unitarioCaixa) 
          : 
          rows[i].unitario
        )

      const sufixoUnidade = rows[i].unidadesCaixaAparente
          ?
          (rows[i].pesoCaixa > 0 ? `${getLabelNivelCaixaria(rows[i].nivelCaixaria)}(${formatPeso(rows[i].pesoCaixa)} kg)` :  `${getLabelNivelCaixaria(rows[i].nivelCaixaria)}(${rows[i].unidadesCaixa} un)`)
          :(rows[i].unidadesCaixa 
            ? 
            `cx(${rows[i].unidadesCaixa} un)`
            : 
            ''
          )

    rowsFormated.unshift({
      produto: `${rows[i].produto.nome}${sufixoUnidade !== '' ? ` - ${sufixoUnidade}` : ''}`,
      unidades: unidades ? unidades : '-',
      peso: disablePeso(rows[i].produto) ? formatPeso(rows[i].peso) : '-',
      unitario: `${formatMoeda(
        unitario,
      )}`,
      total: formatMoeda(rows[i].total),
      uidd: rows[i].uidd,
      numeroItem: i + 1,
      tipo_oferta: rows[i].tipo_oferta
    });
  }
  return rowsFormated;
}

export function toStringParcela(rows) {
  const rowsFormated = [];
  for (let i = 0; i < rows.length; i += 1) {
    rowsFormated.unshift({
      uidd: rows[i].uidd,
      valor: formatMoeda(rows[i].valor),
      tipo_pagamento: rows[i].tipo_pagamento.nome,
      data_pagamento: moment(rows[i].data_pagamento).format('DD/MM/YYYY'),
      valor_recebido: (rows[i].tipo_pagamento.dinheiro_vivo && rows[i].tipo_pagamento.modalidade === A_VISTA) ? formatMoeda(rows[i].valor_recebido) : '-',
      troco: (rows[i].tipo_pagamento.dinheiro_vivo && rows[i].tipo_pagamento.modalidade === A_VISTA) ? formatMoeda(rows[i].troco) : '-',
      numeroItem: i + 1,
    });
  }
  return rowsFormated;
}

export function getTotal(peso, unidades, precoUnitario, produto) {
  if (produto.unidade.tipo === POR_UNIDADE) {
    return trunc10(unidades * precoUnitario, -2);
  }
  return trunc10(peso * precoUnitario, -2);
}

export function getSubTotal(itens) {
  let soma = 0;
  for (let i = 0; i < itens.length; i += 1) {
    soma += itens[i].total;
  }
  return soma;
}

export function searchItemInArray(produto, precoUnitario, itens) {
  for (let i = 0; i < itens.length; i += 1) {
    if (produto.id === itens[i].produto.id && precoUnitario === itens[i].unitario) return i;
  }
  return -1;
}

export function renameParcelasUIDD(parcelas) {
  const arrayNew = parcelas.slice();
  for (let i = 0; i < parcelas.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].tipo_pagamento.nome}${i}`;
  }
  return arrayNew;
}

export function renameItensUIDD(itens) {
  const arrayNew = itens.slice();
  for (let i = 0; i < itens.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].produto.nome}${i}`;
  }
  return arrayNew;
}
