import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { api, getUsername } from '../../../services';
import { SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR } from '../../../utils';
import {
  IntegerInput,
  TextInput,
} from '../../Inputs';
import DialogoSenha from '../DialogoSenha'
import DialogoAutorizacaoComQrcode from '../DialogoAutorizacaoComQrcode'


const inputs = [
  {
    nome: 'numeroItem',
    defaultValue: -1,
    label: 'Código Item',
  },
  {
    nome: 'senha',
    defaultValue: '',
    label: 'Senha',
  },
];

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const DialogoNota = forwardRef(({
  handleCancelar = () => {}, 
  handleSalvar, 
  title = 'titulo', 
  enqueueSnackbar, 
  mensagemRemovido = 'Item removido com sucesso!',
  titleAutorizacao = 'titulo',
  mensagemRemovidoAutorizacao = 'Item removido com sucesso!',
}, ref) => {
  const [open, setOpen] = useState(false);
  const [codigoItem, setCodigoItem] = useState('');
  const refDialogoSenha = useRef(null);
  const refDialogoAutorizacaoComQrcode = useRef(null);

  const handleCloseDialog = () => {
    setOpen(false);
    handleCancelar();
  };

  /*async function handleSalvarDialog() {
    if (!erroExistente) {
      const response = await api.post('/confere_senha', {
        username: getUsername(),
        password
      });
      if (response.data.status) {
        handleSalvar(numeroItem);
        setOpen(false);
        setPassword('');
        enqueueSnackbar(mensagemRemovido, {
          variant: 'success',
        });
      } else {
        setOpen(false);
        setPassword('');
        enqueueSnackbar('Senha incorreta!', {
          variant: 'error',
        });
      }
    }
  }*/

  async function handleSalvarDialog() {
    if (!erroExistente) {
      /*if (refDialogoSenha.current) {
        refDialogoSenha.current.handleOpen();
      }*/
      if (refDialogoAutorizacaoComQrcode.current) {
        refDialogoAutorizacaoComQrcode.current.handleOpen();
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigoItem('');
    },
  }));

  function handleKey(action, name) {
    switch (name) {
      case FECHAR_VENDA_ACTION:
        handleSalvarDialog();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog();
        break;
      case ESC_ACTION:
        handleCloseDialog();
        break;
      default:
        break;
    }
  }

  function validateNumeroItem() {
    let error = '';
    if (codigoItem.length === 0) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = [
      '',
    ];
    errosOld[0] = validateNumeroItem();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/\D/g, '');
    }
    return '';
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle 
          id="form-dialog-title" 
          style={{ 
            color: 'white', 
            backgroundColor: SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <TextInput
            name='codigo'
            handleEnter={() => {
              handleSalvarDialog()
            }}
            label='Código'
            handleKey={handleKey}
            value={codigoItem}
            onChange={(value) => setCodigoItem(limparCodigo(value.target.value))}
            fullWidth
            autoFocus
            error={erros[0] !== ''}
            helperText={erros[0]}
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Cancelar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSalvarDialog}
            disabled={erroExistente}
            tabindex="-1"
          >
            {`Confirmar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
        {/*<DialogoSenha
          ref={refDialogoSenha}
          handleClose={() => {}}
          enqueueSnackbar={enqueueSnackbar}
          handleCancelar={() => {}}
          handleSalvar={(codigo) => {
            handleSalvar(codigoItem);
            setOpen(false);
          }}
          mensagemSucesso={mensagemRemovidoAutorizacao}
          title={titleAutorizacao}
        />*/}
        <DialogoAutorizacaoComQrcode
          ref={refDialogoAutorizacaoComQrcode}
          handleClose={() => {}}
          enqueueSnackbar={enqueueSnackbar}
          handleSalvar={(user_id) => {
            handleSalvar(codigoItem, user_id);
            setOpen(false);
          }}
          mensagemSucesso={mensagemRemovidoAutorizacao}
          title={titleAutorizacao}
        />
      </Dialog>
    </div>
  );
});

export default DialogoNota;
