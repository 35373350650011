import React, { useReducer, useEffect } from 'react';
import {
  Route, Switch, useRouteMatch, useHistory,
} from 'react-router-dom';

import {
  getSessionId,
} from '../../services';
import InicioFrenteCaixaVenda from './_SubPages/Itens';
import { VendaContext } from './context';
import reducer from './reducer';

const CreateVenda = () => {
  const history = useHistory();

  const [venda, dispatch] = useReducer(reducer, {
    cliente: null,
    itens: [],
    itensExcluidos: [],
    parcelas: [],
    idEdit: -1,
    isEditPrice: false,
    tipoVenda: 0,
  });
  const { path } = useRouteMatch();

  useEffect(() => {
    async function getDefaults() {
      dispatch({ type: 'UPDATE_CLIENTE', cliente: null });
    }
    getDefaults();
  }, []);

  function irParaTelaInit() {
    history.push('/');
  }

  return (
    <VendaContext.Provider value={{ venda, dispatch }}>
      <Switch>
        <Route
          path={`${path}/frentedecaixa`}
          render={(props) => (
            <InicioFrenteCaixaVenda
              irParaTelaInit={irParaTelaInit}
              getSessionId={() => getSessionId()}
            />
          )}
        />
      </Switch>
    </VendaContext.Provider>
  );
};

export default CreateVenda;
