import { getSessionId, setVendaCancelada } from '../services/alth'
import { A_VISTA } from '../utils'

export function montarObjVendaRatoeira(dados) {
    const {
      itens,
      parcelas,
      cliente,
      isEmPartes,
      cpf,
      uuid
    } = dados

    const listaItens = [];
    const listaParcelas = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_venda: itens[i].unitario,
        preco_compra: itens[i].produto.precoCompraMedio,
        produto_id: itens[i].produto.id,
        idIndicadorChange: itens[i].idIndicadorChange,
        unidades_caixa: itens[i].unidadesCaixa,
        preco_venda_caixa: itens[i].unitarioCaixa,
        peso_caixa: itens[i].pesoCaixa,
        unidades_caixa_aparente: itens[i].unidadesCaixaAparente,
        nivel_caixaria: itens[i].nivelCaixaria,
      });
    }

    for (let i = 0; i < parcelas.length; i += 1) {
      listaParcelas.push({
        tipo_pagamento_id: parcelas[i].tipo_pagamento.id,
        data_pagamento: parcelas[i].data_pagamento,
        valor: parcelas[i].valor,
        data_pagamento_real:
                parcelas[i].tipo_pagamento.modalidade === A_VISTA ? new Date() : null,
        valor_recebido:
          (parcelas[i].tipo_pagamento.dinheiro_vivo
            && parcelas[i].tipo_pagamento.modalidade === A_VISTA
          )
            ? parcelas[i].valor_recebido : 0,
        idIndicadorChange: parcelas[i].idIndicadorChange,
      });
    }

    const venda = {
      listaItens,
      listaParcelas,
      data: new Date(),
      cliente_id: cliente ? cliente.id : null,
      sessao_id: getSessionId(),
      is_em_partes: isEmPartes,
      cpf,
      uuid
    };

    setVendaCancelada(venda)
}