import React, {
  useImperativeHandle,
  forwardRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import imgQrcode from '../../../assets/qrcode_pix.jpeg';
import { SECONDARY_COLOR, ATALHO_AVANCAR, ATALHO_VOLTAR } from '../../../utils';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;

const DialogoNota = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title" style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}>
          QRCode Pix Caruaru Frios
        </DialogTitle>
        <DialogContent>
          <img src={imgQrcode} alt="" />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoNota;
