import React, {
  useContext, forwardRef, useImperativeHandle, useRef,
} from 'react';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import Chip from '@material-ui/core/Chip';
import SyncIcon from '@material-ui/icons/Sync';

import { AppContext } from '../../app/context';
import { PRIMARY_COLOR, APP_VERSION, APP_VERSION_FUNCIONALIDADES } from '../../utils';

const useStyles = makeStyles((theme) => ({
  settings: {
    color: PRIMARY_COLOR,
  },
  icon: {
    color: theme.palette.secondary.main,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

//const limiteRetirada = 1500;

const MenuMaisOpcies = forwardRef(({ saldoIndicadorRetirada }, ref) => {
  const {
    app: { saldoCaixa },
  } = useContext(AppContext);

  useImperativeHandle(ref, () => ({
    open() {
    },
  }));

  const versao = `Versão: ${APP_VERSION}`;

  const isSaldoAcimaLimiteRetirada = (saldoCaixa >= saldoIndicadorRetirada);

  return (
    <div>
      <div style={{ position: 'fixed', top: '10px', right: '20px' }}>
        <Chip
          icon={<SyncIcon />}
          label={versao}
          variant="outlined"
          color="primary"
          className={isSaldoAcimaLimiteRetirada ? 'btnSinalizadorRetirada' : ''}
        />
      </div>
    </div>
  );
});

export default MenuMaisOpcies;
