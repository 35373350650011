const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SALDO_CAIXA':
      return {
        ...state,
        saldoCaixa: action.saldoCaixa,
      };
    case 'UPDATE_MENSAGEM_TELA_INICIAL_FC':
      return {
        ...state,
        mensagemInicialFc: action.mensagemInicialFc,
      };
    case 'UPDATE_SALDO_INDICADOR_RETIRADA':
      return {
        ...state,
        saldoIndicadorRetirada: action.saldoIndicadorRetirada,
      };
    case 'UPDATE_MEIOS_PAGAMENTO':
      return {
        ...state,
        meiosPagamento: action.meiosPagamento,
      };
    case 'UPDATE_LIMITES_CPF_NOTA':
      return {
        ...state,
        limiteInferiorNotaCpf: action.limiteInferiorNotaCpf,
        limiteSuperiorNotaCpf: action.limiteSuperiorNotaCpf,
      };
    case 'UPDATE_VIDEOS_TUTORIAIS':
      return {
        ...state,
        videosTutoriais: action.videosTutoriais,
      };
    default:
      return state;
  }
};

export default reducer;
