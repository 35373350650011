export const CAIXA_ID = '@CaixaId';
export const TIPO_CAIXA_ID = '@TipoCaixaId';

export const getCaixaId = () => {
  const caixaId = localStorage.getItem(CAIXA_ID);
  return caixaId ? parseInt(caixaId, 10) : null;
};

export const setCaixaId = (caixaId) => localStorage.setItem(CAIXA_ID, String(caixaId));

export const getTipoCaixaId = () => {
  const tipoCaixaId = localStorage.getItem(TIPO_CAIXA_ID);
  return tipoCaixaId ? parseInt(tipoCaixaId, 10) : null;
};

export const setTipoCaixaId = (tipoCaixaId) => localStorage.setItem(TIPO_CAIXA_ID, String(tipoCaixaId));
