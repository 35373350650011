import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles
} from '@material-ui/core';

import {
  TextInput,
} from '../../../components';
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  SECONDARY_COLOR,
  formatMoeda
} from '../../../utils';
import DialogoContadorCedulas from '../../../components/Dialogos/DialogoContadorCedulas'
import { useSnackbar } from 'notistack';
import { api } from '../../../services'
import DialogoAutorizacaoTransportador from '../../../components/Dialogos/DialogoAutorizacaoTransportador'

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const ESC_ACTION = 'Escape';

const useStyles = makeStyles((theme) => createStyles({
  action: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  disabledButton: {
    backgroundColor: 'red'
  }
}));

const DialogoConfirmacao = forwardRef(({
  handleSalvar,
}, ref) => {
  const [open, setOpen] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [username, setUsername] = useState('');
  const [objNotas, setObjNotas] = useState({
    moedas_1_centavo: 0,
    moedas_5_centavos: 0,
    moedas_10_centavos: 0,
    moedas_25_centavos: 0,
    moedas_50_centavos: 0,
    moedas_1_real: 0,
    cedulas_2_reais: 0,
    cedulas_5_reais: 0,
    cedulas_10_reais: 0,
    cedulas_20_reais: 0,
    cedulas_50_reais: 0,
    cedulas_100_reais: 0,
    cedulas_200_reais: 0,
  })
  const refDialogoContadorCedulas = useRef(null)
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [totalTroco, setTotalTroco] = useState(0)
  const refDialogoAutorizacaoTransportador = useRef(null);

  async function getTroco(codigo) {
    const response = await api.get(`/buscar_troco_por_codigo/${codigo}`)
    const {
      status,
      message,
      moedas_5_centavos,
      moedas_10_centavos,
      moedas_25_centavos,
      moedas_50_centavos,
      moedas_1_real,
      cedulas_2_reais,
      cedulas_5_reais,
      cedulas_10_reais,
      cedulas_20_reais,
      cedulas_50_reais,
      cedulas_100_reais,
      cedulas_200_reais, 
      total,
      usernameTransportador
    } = response.data

    if(status) {
      return {
        moedas_5_centavos,
        moedas_10_centavos,
        moedas_25_centavos,
        moedas_50_centavos,
        moedas_1_real,
        cedulas_2_reais,
        cedulas_5_reais,
        cedulas_10_reais,
        cedulas_20_reais,
        cedulas_50_reais,
        cedulas_100_reais,
        cedulas_200_reais, 
        total,
        usernameTransportador
      }
    } else {
      enqueueSnackbar(
        message,
        {
          variant: 'error',
        },
      );
      return null
    }
  }

  function handleCloseLocal() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigo('');
      setObjNotas({
        moedas_1_centavo: 0,
        moedas_5_centavos: 0,
        moedas_10_centavos: 0,
        moedas_25_centavos: 0,
        moedas_50_centavos: 0,
        moedas_1_real: 0,
        cedulas_2_reais: 0,
        cedulas_5_reais: 0,
        cedulas_10_reais: 0,
        cedulas_20_reais: 0,
        cedulas_50_reais: 0,
        cedulas_100_reais: 0,
        cedulas_200_reais: 0,
      })
      setTotalTroco(0)
      setUsername('')
    },
  }));

  async function salvarLocal() {
    if(erroCodigo === '') {
      const pacote = await getTroco(codigo)
      if(pacote) {
        setObjNotas({
          moedas_1_centavo: 0,
          moedas_5_centavos: pacote.moedas_5_centavos,
          moedas_10_centavos: pacote.moedas_10_centavos,
          moedas_25_centavos: pacote.moedas_25_centavos,
          moedas_50_centavos: pacote.moedas_50_centavos,
          moedas_1_real: pacote.moedas_1_real,
          cedulas_2_reais: pacote.cedulas_2_reais,
          cedulas_5_reais: pacote.cedulas_5_reais,
          cedulas_10_reais: pacote.cedulas_10_reais,
          cedulas_20_reais: pacote.cedulas_20_reais,
          cedulas_50_reais: pacote.cedulas_50_reais,
          cedulas_100_reais: pacote.cedulas_100_reais,
          cedulas_200_reais: pacote.cedulas_200_reais,
        })
        setTotalTroco(pacote.total)
        setUsername(pacote.usernameTransportador)
        if(refDialogoContadorCedulas.current) {
          refDialogoContadorCedulas.current.handleOpen()
        }
      }
    } else {
      enqueueSnackbar(
        'É obrigatório 13 dígitos no código!',
        {
          variant: 'error',
        },
      );
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        salvarLocal();
        break;
      case CANCELAR_VENDA_ACTION:
        setCodigo('');
        setOpen(false);
        break;
      case ESC_ACTION:
        setCodigo('');
        setOpen(false);
        break;
      default:
        break;
    }
  }

  function validateCodigo() {
    if (codigo.length !== 13) {
      return 'Este campo deve conter 13 dígitos!';
    }
    return '';
  }

  const erroCodigo = validateCodigo();

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/\D/g, '');
    }
    return '';
  }

  function comparaCedulas(cedulas1, cedulas2) {
    if(cedulas1.moedas_1_centavo !== cedulas2.moedas_1_centavo) return false
    if(cedulas1.moedas_5_centavos !== cedulas2.moedas_5_centavos) return false
    if(cedulas1.moedas_10_centavos !== cedulas2.moedas_10_centavos) return false
    if(cedulas1.moedas_25_centavos !== cedulas2.moedas_25_centavos) return false
    if(cedulas1.moedas_50_centavos !== cedulas2.moedas_50_centavos) return false
    if(cedulas1.moedas_1_real !== cedulas2.moedas_1_real) return false
    if(cedulas1.cedulas_2_reais !== cedulas2.cedulas_2_reais) return false
    if(cedulas1.cedulas_5_reais !== cedulas2.cedulas_5_reais) return false
    if(cedulas1.cedulas_10_reais !== cedulas2.cedulas_10_reais) return false
    if(cedulas1.cedulas_20_reais !== cedulas2.cedulas_20_reais) return false
    if(cedulas1.cedulas_50_reais !== cedulas2.cedulas_50_reais) return false
    if(cedulas1.cedulas_100_reais !== cedulas2.cedulas_100_reais) return false
    if(cedulas1.cedulas_200_reais !== cedulas2.cedulas_200_reais) return false
    return true
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle 
          id="alert-dialog-title" 
          style={{ 
            color: 'white', 
            backgroundColor: SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          CARREGAR TROCO
        </DialogTitle>
        <DialogContent>
          <TextInput
            name="codigo"
            handleEnter={salvarLocal}
            label=""
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            value={codigo}
            onChange={(value) => setCodigo(limparCodigo(value.target.value))}
            fullWidth
            autoFocus
            style={{
              marginTop: '10px',
              backgroundColor: 'white',
            }}
            error={erroCodigo !== ''}
            helperText={erroCodigo}
          />
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button 
            onClick={handleCloseLocal} 
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={salvarLocal}
            color="secondary"
            variant="contained"
            tabindex="-1"
            //disabled={erroCodigo !== ''}
          >
            {`Carregar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogoContadorCedulas
        ref={refDialogoContadorCedulas}
        handleClose={() => {}}
        handleSalvar={(total, cedulas) => {
          if(comparaCedulas(objNotas, cedulas)) {
            //handleSalvar(codigo);
            //setCodigo('');
            if (refDialogoAutorizacaoTransportador.current) {
              refDialogoAutorizacaoTransportador.current.handleOpen(0, cedulas, username);
            }
          } else {
            enqueueSnackbar(
              'As cédulas digitadas não correspondem às cédulas do troco carregado!',
              {
                variant: 'error',
              },
            );
          }
        }}
        getTitle={(total) => `Total (${formatMoeda(total)}) - Troco (${formatMoeda(totalTroco)})`}
      />
      <DialogoAutorizacaoTransportador
        ref={refDialogoAutorizacaoTransportador}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id, dados, codigo2) => {
          handleSalvar(codigo, user_id, user_id);
          setCodigo('');
        }}
        mensagemSucesso="Carregamento do troco inicial autorizado!"
        title="Autorização carregamento do troco inicial!"
        hasTransportador
      />
    </div>
  );
});

export default DialogoConfirmacao;
