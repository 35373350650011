import React, {
    useImperativeHandle,
    forwardRef,
    useState,
    useRef
  } from 'react';
  
  import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
  } from '@material-ui/core';
  import { useTheme } from '@material-ui/core/styles';
  
  import {
    IntegerInput,
  } from '../../../components';
  import {
    ATALHO_VOLTAR,
    ATALHO_AVANCAR,
    SECONDARY_COLOR
  } from '../../../utils';

  import { api } from '../../../services'
import DialogoAutorizacaoComQrcode from '../../../components/Dialogos/DialogoAutorizacaoComQrcode'


  import DialogoAlteraCaixa from '../DialogoAlteraCaixa'
  
  const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
  const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
  const ESC_ACTION = 'Escape';
  
  const DialogoConfirmacao = forwardRef(({
    handleFail,
    handleSuccess,
    enqueueSnackbar
  }, ref) => {
    const [open, setOpen] = useState(false);
    const [sessao, setSessao] = useState(Number.NaN);
    const theme = useTheme();
    const refDialogoAutorizacaoComQrcode = useRef(null);

    const refDialogoSenha = useRef(null)
  
    function handleCloseLocal() {
      setOpen(false);
    }
  
    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
        setSessao(Number.NaN)
      },
    }));

    async function getDadosSessaoAberta() {
        const response = await api.get(`/dados-sessao-aberta/${sessao}`)
        const { 
            status, 
            caixaId,
            controleEstoqueRefreshToken,
            controleEstoqueToken,
            sessionId,
            tipoCaixaId,
            userId,
            username, 
            message
        } = response.data

        if(status) {
            handleSuccess({
                caixaId,
                controleEstoqueRefreshToken,
                controleEstoqueToken,
                sessionId,
                tipoCaixaId,
                userId,
                username, 
            })
        } else {
            handleFail(message)
        }
    }
  
    function handleActions(action) {
      switch (action) {
        case FECHAR_VENDA_ACTION:
          if(error === '') {
            if(refDialogoAutorizacaoComQrcode.current) {
              refDialogoAutorizacaoComQrcode.current.handleOpen()
            }
          }
          break;
        case CANCELAR_VENDA_ACTION:
            handleCloseLocal()
            break;
        case ESC_ACTION:
          handleCloseLocal()
            break;
        default:
            break;
      }
    }

    function validateInteger() {
      let error = '';
        if (sessao <= 0 || Number.isNaN(sessao)) {
          error = 'Este campo é obrigatório';
        }
      return error;
    }

    const error = validateInteger()
  
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseLocal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="xs"
          //PaperProps={{ style: { backgroundColor: theme.palette.secondary.main } }}
        >
          <DialogTitle 
            id="alert-dialog-title" 
            style={{ 
              color: 'white', 
              backgroundColor: SECONDARY_COLOR, 
              textAlign: 'center' 
            }}
          >
            RECONECTAR SESSÃO
          </DialogTitle>
          <DialogContent>
            <IntegerInput
              name="sessao"
              handleEnter={() => {
                if(error === '') {
                  if(refDialogoSenha.current) {
                    refDialogoSenha.current.handleOpen()
                  }
                }
              }}
              label="Id da Sessão"
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
              value={Number.isNaN(sessao) ? '' : String(sessao)}
              onChange={(value) => setSessao(parseInt(value.target.value, 10))}
              style={{
                marginTop: '10px',
                backgroundColor: 'white',
              }}
              error={error !== ''}
              helperText={error}
              fullWidth
              autoFocus
            />
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => handleActions(CANCELAR_VENDA_ACTION)} color="secondary" variant="contained">
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button onClick={() => handleActions(FECHAR_VENDA_ACTION)} color="secondary" variant="contained" disabled={error !== ''}>
              {`Reconectar (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
        {/*<DialogoAlteraCaixa
          ref={refDialogoSenha}
          handleCancelar={() => {}}
          handleSalvar={() => {
            getDadosSessaoAberta();
          }}
          title="Autorização para se conectar a sessão!"
          enqueueSnackbar={enqueueSnackbar}
          mensagemSucesso='Autorizado a se conectar!'
        />*/}
        <DialogoAutorizacaoComQrcode
          ref={refDialogoAutorizacaoComQrcode}
          handleClose={() => {}}
          enqueueSnackbar={enqueueSnackbar}
          handleSalvar={(user_id) => {
            getDadosSessaoAberta();
          }}
          mensagemSucesso='Autorizado a se conectar!'
          title="Autorização para se conectar a sessão!"
        />
      </div>
    );
  });
  
  export default DialogoConfirmacao;
  