import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import {
  getListAllAPI,
} from '../../../services';
import {
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_VARIAVEL,
  formatMoeda,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
  getNivelCaixaCodigo,
  getNivelCaixaQtde,
  getPrecos,
  getLabelUnidade,
  SECONDARY_COLOR
} from '../../../utils';
import SearchProduto from '../../Inputs/SearchProduto';
import {
  getPrecoUnitarioVerdadeiroCaixa2
} from '../../../utils/precoVenda'

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const ESC_ACTION = 'Escape';

const DialogoConfirmacao = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [produto, setProduto] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const refSearch = useRef(null);
  const theme = useTheme();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setProduto(null);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSalvar = () => {
    setOpen(false);
  };

  function handleActions(name) {
    switch (name) {
      case CANCELAR_VENDA_ACTION:
        handleClose();
        break;
      case ESC_ACTION:
        handleClose();
        break;
      default:
        break;
    }
  }

  function analisaCodigo(codigo) {
    if (codigo.length === 13 && codigo.charAt(0) === '2') {
      return {
        tipo: 0,
        data: {
          codigo_interno: codigo.substring(1, 7),
          peso:
              (parseInt(codigo.substring(7, 9), 10)
              + parseInt(codigo.substring(9, 12), 10) / 1000),
          digitoVerificador: parseInt(codigo.substring(12, 13), 10),
        },
      };
    }
    return {
      tipo: 1,
      data: {},
    };
  }

  async function searchHandle(codigo, id) {
    if(!id) {
      const { tipo, data } = analisaCodigo(codigo);
      if (tipo === 0) {
        const { codigo_interno, peso } = data;
        const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
          codigo_interno, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true
        }, ['unidade']);
        if (produtoData.data.length > 0) {
          if (
            produtoData.data[0].precoCompraMedio >= 0
            && produtoData.data[0].precoCompraMedioImpostos >= 0
          ) {
            if (produtoData.data[0].preco_venda > 0 && produtoData.data[0].preco_revenda > 0) {
              if (
                produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                || produtoData.data[0].unidade.tipo === POR_PESO_VARIAVEL
              ) {
                setProduto(null);
                if (refSearch.current) refSearch.current.focus();
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Este produto não usa balança, deve-se especificar apenas unidades, e não o peso!', {
                  variant: 'error',
                });
              }
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Este produto possui o preço de venda ou revenda inválido!', {
                variant: 'error',
              });
            }
          } else {
            enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
              variant: 'error',
            });
          }
        } else {
          setProduto(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(`Produto não encotrado com este codigo interno: ${codigo_interno}!`, {
            variant: 'error',
          });
        }
      } else {
        const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
          codigo, is_kit: false, is_caixa: false, status: STATUS_ATIVO, ofertasProdutos: true
        }, ['unidade']);
        if (produtoData.data.length > 0) {
          if (
            produtoData.data[0].precoCompraMedio >= 0
            && produtoData.data[0].precoCompraMedioImpostos >= 0
          ) {
            if (produtoData.data[0].preco_venda > 0 && produtoData.data[0].preco_revenda > 0) {
              setProduto(produtoData.data[0]);
            } else {
              if (refSearch.current) refSearch.current.focus();
              enqueueSnackbar('Este produto possui o preço de venda ou revenda inválido!', {
                variant: 'error',
              });
            }
          } else {
            enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
              variant: 'error',
            });
          }
        } else {
          const produtoDataCaixa = await getListAllAPI('produtos/caixa', ['id', 'asc'], { codigo, status: STATUS_ATIVO, ofertasProdutos: true }, ['unidade']);
          if (produtoDataCaixa.data.length > 0) {
            if (
              produtoDataCaixa.data[0].precoCompraMedio >= 0
              && produtoDataCaixa.data[0].precoCompraMedioImpostos >= 0
            ) {
              if (produtoDataCaixa.data[0].preco_venda > 0 && produtoDataCaixa.data[0].preco_revenda > 0) {
                produtoDataCaixa.data[0].nivelCaixa = getNivelCaixaCodigo(produtoDataCaixa.data[0], codigo);
                if (produtoDataCaixa.data[0].nivelCaixa > 0) {
                  setProduto(produtoDataCaixa.data[0]);
                } else {
                  setProduto(null);
                  if (refSearch.current) refSearch.current.focus();
                }
              } else {
                if (refSearch.current) refSearch.current.focus();
                enqueueSnackbar('Este produto possui o preço de venda ou revenda inválido!', {
                  variant: 'error',
                });
              }
            } else {
              enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
                variant: 'error',
              });
            }
          } else {
            setProduto(null);
            if (refSearch.current) refSearch.current.focus();
          }
        }
      }
    } else {
      const produtoData = await getListAllAPI('produtos', ['id', 'asc'], {
        id, ofertasProdutos: true
      }, ['unidade']);
      if (produtoData.data.length > 0) {
        if (
          produtoData.data[0].precoCompraMedio >= 0
          && produtoData.data[0].precoCompraMedioImpostos >= 0
        ) {
          if (produtoData.data[0].preco_venda > 0 && produtoData.data[0].preco_revenda > 0) {
            setProduto(produtoData.data[0]);
          } else {
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar('Este produto possui o preço de venda ou revenda inválido!', {
              variant: 'error',
            });
          }
        } else {
          enqueueSnackbar('Este Produto não possui nenhum lote cadastrado!', {
            variant: 'error',
          });
        }
      }
    }

    setSearch('');
  }

  function getDadosProduto() {
    if (produto) {
      const precoOriginal = produto.preco_venda
      const ofertaPreco = getPrecoUnitarioVerdadeiroCaixa2({...produto, nivelCaixa: 0}, 0, 0)
      const isOferta = precoOriginal > ofertaPreco
      return {
        nomeProduto: produto.nome,
        precos: getPrecos({...produto, nivelCaixa: getNivelCaixaQtde(produto)}),
        sufixo: getLabelUnidade(produto.unidade.tipo),
        isOferta,
        precoOriginal,
        ofertaPreco
      };
    }
    return {
      nomeProduto: '',
      precos: [],
      sufixo: '',
      isOferta: false,
      precoOriginal: 0,
      ofertaPreco: 0
    };
  }

  const { nomeProduto, precos, sufixo, isOferta, ofertaPreco, precoOriginal } = getDadosProduto();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        //PaperProps={{ style: { backgroundColor: theme.palette.secondary.main } }}
      >
        <DialogTitle 
          id="alert-dialog-title" 
          /*style={{ 
            color: 'white', 
            textAlign: 'center', 
            fontWeight: 'bold' 
          }}*/
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR, textAlign: 'center' }}
        >
          CONSULTOR DE PREÇOS
        </DialogTitle>
        <DialogContent>
          <div>
            <SearchProduto
              label="Pesquisar produto"
              value={search}
              onChange={(e) => setSearch(e)}
              fullwidth
              searchHandle={searchHandle}
              ref={refSearch}
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
            />
            { produto && (
            <div style={{ color: SECONDARY_COLOR, marginTop: '25px' }}>
              <div style={{ fontSize: '25px', textAlign: 'center', fontWeight: 'bold' }}>{`${nomeProduto}`}</div>
              <div style={{ fontSize: '25px', marginTop: '15px' }}>
                <div style={{}}>Preços:</div>
                <div style={{ marginLeft: '15px' }}>
                  { !isOferta ? <div style={{ marginTop: '10px' }}>{ `${formatMoeda(precoOriginal)}/${sufixo}`}</div>
                    : <div style={{ marginTop: '10px' }}>{ `Em oferta: ${formatMoeda(ofertaPreco)}/${sufixo} (Preço original: ${formatMoeda(precoOriginal)}/${sufixo}; Desconto: ${formatMoeda(precoOriginal - ofertaPreco)}/${sufixo})`}</div>}
                  {precos.map((itemPreco) => <div style={{ marginTop: '15px' }}>{ `${formatMoeda(itemPreco.preco)}/${itemPreco.sufixo} (${itemPreco.qtde})`}</div>)}
                </div>
              </div>
            </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleSalvar} 
            color="secondary" 
            variant="contained"
            //style={{ color: 'white' }}
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoConfirmacao;
