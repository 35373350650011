import {
  renameItensUIDD,
  renameParcelasUIDD,
  getSubTotal,
  montarObjVendaRatoeira
} from '../../utils';

const reducer = (state, action) => {
  let arrayNew = state.itens.slice();
  let arrayNewParcelas = state.parcelas.slice();
  switch (action.type) {
    case 'ADD_ITEM': {
      const dados = {
        ...state,
        itens: [...arrayNew, action.item],
        subTotal: getSubTotal([...arrayNew, action.item]),
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'REMOVE_ITEM': {
      const removeItens = []
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => {
          if(obj.uidd !== action.indices[i]) {
            return true
          } else {
            removeItens.push({
              ...obj,
              user_id: action.user_id
            })
            return false
          }
        });
      }
      const itensExcluidosAntigos = [...state.itensExcluidos, ...removeItens]
      const dados = {
        ...state,
        itens: [...renameItensUIDD(arrayNew)],
        itensExcluidos: [...renameItensUIDD(itensExcluidosAntigos)],
        subTotal: getSubTotal([...arrayNew]),
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'UPDATE_ITEMS': {
      const dados = {
        ...state,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'UPDATE_CLIENTE': {
      let { parcelas } = state;
      const idClienteAnterior = state.cliente ? state.cliente.id : -1;
      const idClienteNovo = action.cliente ? action.cliente.id : -1;

      if (idClienteAnterior !== idClienteNovo) {
        parcelas = parcelas.filter((item) => item.tipo_pagamento.id !== 11);
      }

      const dados = {
        ...state,
        cliente: action.cliente,
        parcelas,
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'ADD_PARCELA': {
      const dados = {
        ...state,
        parcelas: [...arrayNewParcelas, action.parcela],
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'REMOVE_PARCELA': {
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNewParcelas = arrayNewParcelas.filter(
          (obj) => obj.uidd !== action.indices[i],
        );
      }
      const dados = {
        ...state,
        parcelas: [...renameParcelasUIDD(arrayNewParcelas)],
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'UPDATE_PARCELAS': {
      const dados = {
        ...state,
        parcelas: [...renameParcelasUIDD(action.parcelas)],
      };

      return dados
    }
    case 'UPDATE_ID_EDIT': {
      const dados = {
        ...state,
        idEdit: action.idEdit,
      };

      return dados
    }
    case 'UPDATE_IS_EDIT_PRICE': {
      const dados = {
        ...state,
        isEditPrice: action.isEditPrice,
      };
      return dados
    }
    case 'RESETAR_TUDO': {
      const dados = {
        ...state,
        isEditPrice: false,
        idEdit: -1,
        itens: [],
        itensExcluidos: [],
        parcelas: [],
        subTotal: 0,
        cliente: null,
        tipoVenda: 0,
        isEmPartes: false,
        uuid: null
      };

      return dados
    }
    case 'PREENCHER_TUDO': {
      const dados = {
        ...state,
        isEditPrice: false,
        idEdit: action.idEdit,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
        parcelas: [...renameParcelasUIDD(action.parcelas)],
        cliente: action.cliente,
        tipoVenda: action.tipoVenda,
        isEmPartes: action.is_em_partes,
        uuid: null
      };

      return dados
    }
    case 'PREENCHER_TUDO_PEDIDO': {
      const dados = {
        ...state,
        isEditPrice: false,
        idEdit: -1,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
        cliente: action.cliente,
        parcelas: [],
        tipoVenda: 0,
        isEmPartes: false,
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'CHANGE_IS_EM_PARTES': {
      const dados = {
        ...state,
        isEmPartes: !state.isEmPartes,
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'UPDATE_UUID': {
      const dados = {
        ...state,
        uuid: action.uuid,
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'UPDATE_CPF': {
      const dados = {
        ...state,
        cpf: action.cpf,
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    case 'ADD_ITENS_LIBERACOES': {
      const dados = {
        ...state,
        itens: [...renameItensUIDD([...arrayNew, ...action.liberacoes])],
        subTotal: getSubTotal([...arrayNew, ...action.liberacoes]),
      };
      montarObjVendaRatoeira(dados)
      return dados
    }
    default:
      return state;
  }
};

export default reducer;
