import React, { forwardRef, useState } from 'react';

import { TextField } from '@material-ui/core';
import { stringify } from 'query-string';

import { api } from '../../../services';
import { STATUS_ATIVO } from '../../../utils/constants';

export const CpfCnpjInput = forwardRef(
  (
    {
      name,
      handleEnter = () => {},
      handleKey = () => {},
      dispatch,
      enqueueSnackbar,
      ...rest
    },
    ref,
  ) => {
    const [value, setValue] = useState('');

    async function getCliente() {
      const response = await api.get(`/clientes_pesquisa_fc2?${stringify({
        filter: JSON.stringify({
          search: value,
          status: STATUS_ATIVO,
        }),
      })}`);
      const {
        status,
        message,
        cliente,
      } = response.data;
      if (status) {
        dispatch({ type: 'UPDATE_CLIENTE', cliente });
        enqueueSnackbar(message, {
          variant: 'success',
        });
        setValue('');
        handleEnter();
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    }

    function handleEnterLocal() {
      if (value) {
        getCliente();
      } else {
        handleEnter();
      }
    }

    const handleChange = (event) => {
      const newValue = event.target.value;
      setValue(sanitize(newValue));
    };

    const formatMaskCpf = (value) => {
      const newValue = value;
      let valueFormated = newValue.replace(/[^0-9]/g, '');
      valueFormated = valueFormated.replace(/.-/g, '');
      let finalString = '';
      if (valueFormated.length > 3) {
        finalString = `${finalString}${valueFormated.substring(0, 3)}.`;
        if (valueFormated.length > 6) {
          finalString = `${finalString}${valueFormated.substring(3, 6)}.`;
          if (valueFormated.length > 9) {
            finalString = `${finalString}${valueFormated.substring(
              6,
              9,
            )}-${valueFormated.substring(9)}`;
          } else {
            finalString = `${finalString}${valueFormated.substring(6)}`;
          }
        } else {
          finalString = `${finalString}${valueFormated.substring(3)}`;
        }
      } else {
        finalString = `${finalString}${valueFormated.substring(0)}`;
      }
      return finalString;
    };

    function formatMaskCnpj(value) {
      const newValue = value;
      let valueFormated = newValue.replace(/[^0-9]/g, '');
      valueFormated = valueFormated.replace(/.-/g, '');
      if (valueFormated.length > 11) {
        valueFormated = valueFormated.substring(0, 14);
      }
      let finalString = '';
      if (valueFormated.length > 2) {
        finalString = `${finalString}${valueFormated.substring(0, 2)}.`;
        if (valueFormated.length > 5) {
          finalString = `${finalString}${valueFormated.substring(2, 5)}.`;
          if (valueFormated.length > 8) {
            finalString = `${finalString}${valueFormated.substring(5, 8)}/`;
            if (valueFormated.length > 12) {
              finalString = `${finalString}${valueFormated.substring(
                8,
                12,
              )}-${valueFormated.substring(12)}`;
            } else {
              finalString = `${finalString}${valueFormated.substring(8)}`;
            }
          } else {
            finalString = `${finalString}${valueFormated.substring(5)}`;
          }
        } else {
          finalString = `${finalString}${valueFormated.substring(2)}`;
        }
      } else {
        finalString = `${finalString}${valueFormated.substring(0)}`;
      }
      return finalString;
    }

    function sanitize(value) {
      let replaceQuantity = value;
      replaceQuantity = replaceQuantity.replace(/[^0-9,]/g, '');
      return replaceQuantity;
    }

    function formatMask() {
      const valueFormated = value.replace(/[^0-9]/g, '');
      if (valueFormated.length <= 11) {
        return formatMaskCpf(value);
      }
      return formatMaskCnpj(value);
    }

    const valueFormated = formatMask(value);

    return (
      <TextField
        {...rest}
        variant="outlined"
        ref={ref}
        name={name}
        value={valueFormated}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.keyCode === 13) handleEnterLocal();
          else handleKey(e.keyCode, e.key);
        }}
      />
    );
  },
);
